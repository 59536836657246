@import './../abstracts/mixin';

/* ===== @import font-family ====== */

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons+Two+Tone');


@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v85/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}


/* fallback */

@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v56/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}


/* fallback */

@font-face {
    font-family: 'Material Icons Round';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconsround/v55/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2) format('woff2');
}

.material-icons-round {
    font-family: 'Material Icons Round';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}


$f-primary: 'Nunito Sans',
sans-serif;

$allFontWeight:(
    l: 300, // light
    r: 400, // regular
    m: 600, // medium
    b: 700 // bold
);

@mixin fw($key) {
    font-weight: map-get($allFontWeight, $key) !important;
}

//-- FONT WEIGHT CLASS --//
@each $fwName, $fwValue in $allFontWeight{
    .fw--#{$fwName}{
        font-weight: $fwValue !important;
    }
}

:root{
    --ff-body: Nunito Sans, sans-serif;
    --ff-heading: Nunito Sans, sans-serif;
    --fs-root: 16px;
}

// .h2{
//     font-size: 40px;
//     line-height: 1.5;
// }

// .h3{
//     font-size: 35px;
//     line-height: 1.3;
// }

// .h4{
//     font-size: 25px;
// }

// .h5{
//     font-size: 20px;
// }

// .h6{
//     font-size: 18px;
//     line-height: 30px;
// }

.desc{
    font-size: toRem(18);
}

.medium{
    @include fw(m);
}

.semi-bold{
    @include fw(sb);
}

.bold{
    @include fw(b);
}

.small-text{
    font-size: 16px;
}

.two--line,
.three--line,
.four--line{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.two--line{
    -webkit-line-clamp: 2;
}

.three--line{
    -webkit-line-clamp: 3;
}

.four--line{
    -webkit-line-clamp: 4;
}
