@function color($key) {
    @if map-has-key($allColors, $key) {
      @return map-get($allColors, $key);
    }
    @warn "Unknown `#{$key}` in $allColors.";
    @return null;
}

$allColors:(

    // Base Colors
    white: #ffffff,
    black: #000000,
    place: #aeaeae,
    mark: #ffff00,
    border: #e0e0e0,
    lgraybg: #f5f5f5,
    tblborder:  #dadada,
    footerlink: #959595,
    slateblue: #5d6679,
    slatered: #ff6d6d,
    darkYellow: #ce9313,
    lightSlate: #777d8c,
    verylightbg: #e8ecf8,

    // Success and Error Colors
    success: #7ac142,
    error: #d81212,
    red: #e81011,

    // Theme Colors
    lightyellow: #fff8e3,
    lightgrey: #f5f7fa,
    midlght-gray: #686868,
    mediumgray: #807e7f,
    drk-gray: #313131,
    hvy-darkgray: #212121,
    theme-bg: #e7ecf8,
    orange: #d9590c,
    pending: #f55e1b,
    approved: #70c709,
    yellow: #fbc011,
    verylightYellow: #fffbf1,
    yes:  #50b904,
    no: #dd1112,
    dashboardRed: #f21111,
    creamcolor: #ebe2c7,
    veryLightSlate: #e8eaef,


    // Social Media
    facebook: #3b5998,
    twitter: #26a6d1,
    youtube: #ff0000,
    linkedin: #0e76a8,
    whatsapp: #1cd742,

    a10-yellow-2: #e39315,
    a10-maroon: #ae1357,
    a10-blue: #4385F4,
    a10-dblue: #19133D,
    a10-gry: #E8ECF5,
    a10-dgry: #E8E8F5,
    a10-mgry: #F5F5FA,
    a10-lgry: #F8F8FA,
    a10-lgryX2: #FAFAFF,
    a10-grey3: #bfbfbf,
    a10-grey4: #fafafa,
    a10-purple: #9a98dc,
    a10-lpurple: #94a7d0,
    a10-lpurpleX2: #95a7d1,
    a10-lpurpleX3: #dce4f4,
    a10-turquoise: #53c4c9,
    a10-red: #ff0066,
    a10-ylw: #F1C141,
    a10-ltylw: #fff3d1,
    a10-bg: #f9fafb,
    text-opac: #696969,
    text-opac2x: #afafaf,
    a10-pink: #efc7dd,
    a10-lpink: #faeef3,
    a10-drkYlw: #756d57,
    a10-hykpink: #da2c7b,
    

    // facebookold: #3b5998,
    //instagrammagenta: #c32aa3,
    // instagramblue: #4c5fd7,
    // instagrampurple: #7232bd,
    // instagramorange: #f46f30,
    // instagramyellow: #ffdc7d,
    // googleblue: #4285f4,
    // googlered: #ea4335,
    // googleyellow: #fbbc05,
    // googlegreen: #34a853,
    // pinterest: #bd081c,
    // googleplus: #db4437,
    // vimeoblue: #1ab7ea,
    // tumblr: #2c4762,
    // snapchat: #fffc00,
    // whatsappgreen: #25d366,
    // whatsappteal1: #075e54,
    // whatsappteal2: #128c7e,
    // mastodon: #2b90d9,
    // apple: #a6b1b7,
    // amazon: #ff9900,
    // microsoftred: #f35022,
    // microsoftgreen: #80bb03,
    // microsoftblue: #03a5f0,
    // microsoftyellow: #ffb903,
    // periscope: #40a4c4,
    // foursquarepink: #f94877,
    // foursquarenavy: #073282,
    // foursquareblue: #2d5be3,
    // swarm: #ffa633,
    // medium: #02b875,
    // skypeblue: #00aff0,
    // skypedkblue: #0078d7,
    // android: #a4c639,
    // stumbleupon: #e94826,
    // flickrpink: #f40083,
    // flickrblue: #006add,
    // yahoo: #430297,
    // twitch: #6441a4,
    // soundcloud: #ff5500,
    // spotifygreen: #1ed760,
    // spotifydarkgreen: #1db954,
    // dribbble: #ea4c89,
    // slackpurple: #4a154b,
    // slackblue: #36c5f0,
    // slackgreen: #2eb67d,
    // slackred: #e01e5a,
    // slackyellow: #ecb22e,
    // reddit: #ff5700,
    // deviantart: #05cc47,
    // pocket: #ee4056,
    // quora: #aa2200,
    // quorablue: #2b6dad,
    // slideshareorange: #e68523,
    // slideshareblue: #00a0dc,
    // fivehundredpx: #0099e5,
    // vk: #4a76a8,
    // listlyorange: #df6d46,
    // listlyblue: #52b1b3,
    // vine: #00b489,
    // steam: #171a21,
    // discord: #7289da,
    // telegram: #0088cc,
    // clarity: #61bed9  ,
    transparent: transparent
);

@each $colorName, $colorValue in $allColors{
  .bg-#{$colorName}{
      background-color: $colorValue;
  }
  .c-#{$colorName}{
    color: $colorValue;
  }

  .bg-hvr-#{$colorName}:hover{
    background-color: $colorValue !important;
  }
  .c-hvr-#{$colorName}:hover{
    color: $colorValue !important;
  }
}
//   @mixin insta(){
//     background: #d6249f;
//     background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
//   }
