
@import './layout/normalize';
@import './abstracts/util';
@import './abstracts/flex';
@import './components/tab';
// @import './components/menu';
@import './components/buttons';
@import './components/form';
@import './components/accordion';
@import './project/modal';
@import './project/main';
@import "~ngx-tabset/ngx-tabset";
@import "~ng-pick-datetime/assets/style/picker.min.css";
// @import './project/leftmenu';



iframe::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

iframe::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

iframe::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D62929;
}


carousel{
  z-index: 990 !important;
}

.back-btn {
  position: absolute;
  z-index: 9;
  width: 150px;
  td {
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
  }
}
