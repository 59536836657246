@import './../abstracts/mixin';
@import './../abstracts/flex-mixin';
@import './../abstracts/responsive-mixin';
@import './colors';
@import './typo';


hr {
    border: none;
    border-top: 1px solid currentColor;
}

.max-container,
.main-container,
.mid-container,
.small-container {
    width: 100%;
    max-width: var(--maxContWidth);
    padding: 0 15px;
    margin: 0 auto;

}

.main-container {
    --maxContWidth: 1430px;
}

.max-container {
    --maxContWidth: 1950px;
}


:root {
    --spacer: 40px;
    --spacerHalf: 30px;

    @media(min-width: 760px) {
        --spacer: 60px;
        --spacerHalf: 60px;
    }

    @media(min-width: 1400px) {
        --spacer: 120px;
    }
}

.h1 {
    font-size: 40px;
}

.h2 {
    font-size: 30px;
}

.h3 {
    font-size: 26px;
}

.h4 {
    font-size: 20px;

    @media (max-width: 1540px) {
        font-size: 16px;
    }
}

.h5 {
    font-size: 16px;
}

.widgets__block {
    .grid-3 {
        gap: 30px !important;
    }
}

.sds-modal {
    .grid-1 {
        .thumb {
            border: 1px solid gray;
            padding: 20px;
            text-align: center;
            border-radius: 6px;
            margin-bottom: 25px;

            img {
                width: 250px;
            }
        }
    }
}

.carousel__bg {
    background: #fff8e3;
    padding: 2rem;
    border-radius: 10px;
    border: 1px solid #e1dbc8;
    overflow: hidden;
}

.carousel__bg_white {
    background: white;
    overflow: hidden;
}

.thumbnail {
    position: relative;
    border: 6px solid #fbc011;

    img {
        width: 100%;
    }

    span {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 1rem;
    }
}

.logo-upload-btn {
    background: #f2f2f2;
    padding: 12px !important;
    text-align: left;
    margin-bottom: 1rem;
    border-radius: 5px;
    justify-content: flex-start;
    border: 1px solid #ccc;
    width: 100%;
}

.carousel {

    .carousel-container {
        overflow: inherit !important;

        .carousel-cells {
            will-change: auto !important;

            .carousel-cell {
                overflow: inherit !important;

                figure {
                    padding-bottom: 75%;
                    height: 0;
                    position: relative;
                    background: #fff;

                    img,
                    video,
                    iframe {
                        position: absolute !important;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}


.download-links {
    ul {
        li {
            display: grid;
            grid-template-columns: 70% 30%;
            float: left;
            width: 100%;
            padding: 0.5rem 0;

            &:not(:last-child) {
                border-bottom: 1px solid #ccc;
            }
        }
    }
}

.circle__edit {
    background-color: color(white);
    box-shadow: 0 0 15px 5px #e8e8e8 !important;
    width: 30px;
    height: 30px;
    line-height: 2.2;
    text-align: center;
    color: #000000;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 8px;
    margin-top: 0 !important;
    z-index: 9;
    transition: .3s;
    cursor: pointer;

    .material-icons-two-tone {
        font-size: 16px;
    }

    &:hover {
        background-color: color(yellow);
        transition: .3s;
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
}

.footer {
    margin-left: 320px;
    position: relative;
    padding: 0 35px;
    @include d-flex(r, sb, c);
    transition: all .3s ease;

    .copyright {
        font-size: 14px;
        color: #535353;
    }
}

.header {
    margin-left: 320px;
    position: relative;
    transition: all .3s ease;
    z-index: 9;
    @media (max-width: 1500px){
        margin-left: 280px;
    }

    .content-header {
        padding: 14px 25px;
        position: relative;
        background-color: #f5f7fa;
        border-radius: var(--low-radius);
        box-shadow: var(--card-shadow);
        @include d-flex(r, sb, c);
    }

    .content-body {
        padding: 35px 35px 0px 35px;
        float: left;
        width: 100%;

        .page-head {
            display: grid;
            line-height: 1.4;

            &>h1 {
                font-size: 26px;
                @include fw(b);
                color: color(hvy-darkgray);
            }
        }

        .notification-container {
            @include d-flex(r, sb);
            list-style: none;

            li {
                margin: 0 0 0 28px;
                position: relative;

                a,
                .dropbtn-noti {
                    .material-icons-two-tone {
                        font-size: 32px;
                        opacity: .6;
                    }
                }

                .dropbtn-noti,
                .dropbtn-profile {
                    cursor: pointer;

                    &:hover {

                        .dropdown-content-noti,
                        .dropdown-content-profile {
                            transition: 0.2s;
                            opacity: 1;
                            visibility: visible;

                            @media (min-width: 1400px) {
                                padding-top: 26px;
                            }

                            @media (min-width: 951px) and (max-width: 1399px) {
                                padding-top: 22px;
                            }
                        }
                    }
                }

                .dropdown-noti,
                .dropdown-profile {
                    position: relative;
                    display: inline-block;

                    &.accord-hassubmenubtn {
                        &.actv {
                            .accord-hassubmenutarget {
                                @media (max-width: 950px) {
                                    opacity: 1;
                                    visibility: visible;
                                    display: block !important;
                                }
                            }

                        }
                    }
                }

                .dropdown-content-noti {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    z-index: 9;
                    width: 350px;
                    padding-top: 36px;
                    right: -85px;
                    transition: 0.3s;

                    @media (max-width: 950px) {
                        padding-top: 24px;
                    }

                    .notification-block {
                        background-color: color(white);
                        min-width: 160px;
                        overflow: auto;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
                        max-height: 400px;
                        height: 100%;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;

                        @media (max-width: 1399px) {
                            max-height: 300px;
                        }

                        a {
                            color: black;
                            padding: 12px 16px;
                            text-decoration: none;
                            display: block;
                            font-size: 14px;
                            display: flex;
                            margin: 0 !important;
                            border-bottom: 1px solid #f0f0f0;

                            @media (max-width: 1399px) {
                                padding: 10px 16px;
                                font-size: 12px;
                            }

                            &:hover {
                                background: #f0f0f0;
                            }

                            .notification {
                                display: flex;

                                figure {
                                    width: 45px;
                                    height: 45px;
                                    flex: 0 0 45px;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    border: none;
                                    position: relative;
                                    overflow: hidden;

                                    img {
                                        position: absolute;
                                        left: 0;
                                        width: 100%;
                                        min-height: 100%;
                                        object-fit: cover;
                                        object-position: top;
                                    }
                                }

                                article {
                                    padding-left: 10px;

                                    h4 {
                                        font-size: 16px;
                                        line-height: 1.4;
                                        margin-bottom: 5px;
                                    }

                                    p {
                                        font-weight: normal;
                                        font-size: 12px;
                                        color: color(midlght-gray);
                                    }
                                }
                            }
                        }

                        .no-data {
                            padding: 20px;
                            text-align: center;

                            &>figure {
                                width: 120px;
                                margin: 0 auto;
                                margin-bottom: 20px;
                            }

                            &>article {
                                text-transform: capitalize;
                                font-size: 18px;
                                text-align: center;
                                color: #888;
                                font-weight: 400;
                            }
                        }
                    }

                    &.show {
                        display: block;
                        position: absolute;
                        top: 63px;
                        left: auto;
                        right: -110px;

                        .notification-container {
                            width: 400px;
                            height: 350px;
                            overflow-y: auto;
                        }
                    }

                    .notification__footer {
                        background: color(yellow);
                        color: color(black);
                        padding: 5px;
                        font-size: 16px;
                        text-transform: capitalize;
                        border-top: 2px solid #eaeaea;
                        text-align: center;
                        font-weight: 500;
                        transition: .3s;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;

                        &:hover {
                            background: color(maroon);
                            color: white;
                            transition: .3s;
                        }
                    }
                }

                .n-counter {
                    position: absolute;
                    background-color: color(orange);
                    padding: 5px;
                    font-size: 10px;
                    width: 24px;
                    height: 24px;
                    border-radius: var(--oval-radius);
                    text-align: center;
                    color: color(white);
                    line-height: 1.4;
                    top: -10px;
                    right: -10px;
                }
            }
        }
    }
}

.cp--profile__details {
    &>p {
        background: #e6ebf7;
        color: black;
        padding: 1.4rem;
        width: 100%;
        border-radius: 10px;
        font-size: 16px;
    }
}

.light-hr__border {
    border-color: #e1e1e1;
}

.cpanel__webinars__tab{
    padding: 10px 0;
    border-bottom: 1px solid #777;
    margin-bottom: 2rem;
    .tab-item{
        text-decoration: none !important;
        font-size: 18px !important;
        &.highlighted-tab{
            color: #a8382d !important;
            padding: 10px 0;
            border-bottom: 2px solid currentColor;
        }
    }
}

.manage-webinar{
    .manage__chat-grid{
        display: grid;
        grid-template-columns: 70% auto;
        float: left;
        gap: 1.2rem;
        .webinar--chat{

            .chat{
                max-height: 620px;
                overflow: auto;
                padding: 15px;
                background: #e5e5e5;
                border-radius: 5px;

                .chat-item{
                    position: relative;
                    margin-bottom: 50px;
                    background: #ffffff;
                    padding: 1.1rem;
                    border-radius: 0.5rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-color: #ffffff transparent transparent;
                        border-style: solid;
                        border-width: 20px 0 0 20px;
                        position: absolute;
                        left: 30px;
                        bottom: -20px;
                    }

                    h3{
                        font-weight: bold;
                        color: #959595;
                    }
                }
            }

            .chat-footer{
                margin-top: 20px;
            }
        }
    }
    .card-content{
        figure{
            position: relative;
            padding-bottom: 56.25%;
            height: 0;

            img{
                position: absolute;
                width: 100%;
                max-height: 100%;
                left: 0;
                top: 0;
                border: 5px solid #000;
            }
        }

        .webinar-uploader{
            float: left;
            width: 100%;
            .form-upload{
                width: 100%;
            }
        }

        .webinar-and-chat{
            // display: grid;
            // grid-template-columns: 70% auto;
            // gap: 2rem;
            .inside-heading{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 1rem;
            }
        }
    }

    .speaker{
        background: #fcfcfc;
        padding: 30px;
        box-shadow: -15px 15px 0px 0px whitesmoke;

        .__grid{
            display: grid;
            grid-template-columns: 140px auto;
            align-items: center;
            gap: 1.4rem;

            img{
                border-radius: 50%;
                border: 2px solid #ccc;
                width: 100%;
                height: 100%;
            }
        }

        figure {
            margin-right: 20px;
            flex: 0 0 80px;
            width: 80px;
            height: 80px;
        }

        .desig{
            font-size: 20px;
            font-weight: 600;
            line-height: 1.2;
            color: #292929;
        }
    }
}

.cpanel__webinar__data{
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0,1fr));
    gap: 0 1.2rem;
    grid-gap: 0 1.2rem;
    position: relative;
    @media (max-width: 1400px){
        grid-template-columns: repeat(2, minmax(0,1fr));
    }
    .webinar-item {
        margin: 10px 0px;
        padding: 1rem;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0 2px 6px rgba(0,0,0,0.1);

        figure{
            border: 4px solid #ccc;
            margin-bottom: 1.5rem;
        }
        article{
            position: relative;
        }
        .wb_title{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 0.6rem;
            max-width: 80%;
        }
        .caption{
            background-color: color(yellow);
            padding: 3px 12px;
            border-radius: 30px;
            display: inline-block;
            font-size: 14px;
            position: absolute;
            right: 0;
            top: 0;
        }
        .creation-date{
            font-size: 14px;
            margin-bottom: 1rem;
        }
        .votes{
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;
            align-items: center;
            place-content: start;
            margin-bottom: 1rem;
            p{
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                gap: .4rem;
                .material-icons-two-tone{
                    font-size: 18px;
                }
            }
        }
        .author{
            font-size: 18px;
            margin-bottom: 1rem;
        }
        .description{
            font-size: 16px;
            margin-bottom: 1rem;
        }
        .webinar__type{
            display: grid;
            gap: 0.6rem;
            margin-top: 1rem;
            & > *{

                span:not(:first-child){
                    font-size: 14px;
                }
            }
            p{
                display: grid;
                grid-auto-flow: column;
                place-content: start;
                align-items: center;
                gap: 0.4rem;
                .material-icons-two-tone{
                    font-size: 18px;
                }
            }
        }
    }
    .cpanel__webinar__thumbnail{
        position: absolute;
        left: 20px;
        top: -8px;
        background: #fff;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.05);
        border: 6px solid #ebe2c7;
    }
    .cpanel__webinar__banner{
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        margin-bottom: 1.5rem;
        img{
            position: absolute;
            width: 100%;
            max-height: 100%;
            left: 0;
            top: 0;
        }
    }
}

.sidebar-show {
    .sidebar {
        width: 60px;
        transition: all .3s ease;
        z-index: 99;

        .sidebar-header {
            padding: 35px 20px 45px;

            .ham__menu {
                position: absolute;
                top: 10px;
                transition: all .3s ease;
                left: 18px;

                .content-menu {
                    position: relative;

                    &:after {
                        content: '\e5cc';
                        font-family: 'Material Icons Round';
                        font-weight: normal;
                        font-style: normal;
                        font-size: 24px;
                        color: #404040;
                    }
                }
            }

            .sidebar-logo {
                position: absolute;
                top: 46px;
                left: 50%;
                transform: translateX(-50%);
                width: 85%;
                transition: .3s;
            }

            .sidebar-logo-text {
                opacity: 0;
                transition: all .3s ease;
            }

            .profile {
                display: none;
            }
        }

        .sidebar-body {
            .nav-item {
                .nav-link {
                    padding: 0;

                    span {
                        &:last-child {
                            display: none;
                        }
                    }

                }
            }
        }

        &.minimized {
            z-index: 99;

            .sidebar-body .nav-sidebar .nav-link.with-sub::after {
                opacity: 0;
            }

            .sidebar-body .nav-sidebar .nav-item.show {
                .nav-sub {
                    height: 0;
                }
            }
        }

        &.expand {
            width: 320px;
            transition: .3s ease;
            z-index: 99;

            .sidebar-header {
                padding: 35px 20px;

                .ham__menu {
                    top: 38px;
                    left: auto;
                }

                .sidebar-logo {
                    position: relative;
                    top: 0;
                    left: 0;
                    transform: inherit;
                    width: auto;
                }

                .sidebar-logo-text {
                    opacity: 1;
                    transition: all .3s ease;
                }

                .profile {
                    display: flex;
                }
            }

            .sidebar-body {
                .nav-sidebar {
                    padding: 20px;

                    .nav-link {
                        padding: 0 10px;
                    }
                }

                .nav-link {
                    span:last-child {
                        display: block;
                    }
                }
            }

            .sidebar-body .nav-sidebar .nav-link.with-sub::after {
                opacity: 1 !important;
            }

            .sidebar-body .nav-sidebar .nav-item.show {
                .nav-sub {
                    height: auto;
                    max-height: 500px;
                }
            }
        }

    }

    .content,
    .header,
    .footer {
        margin-left: 60px;
        transition: all .3s ease;
    }
}

#fileInput {
    display: none;
}

.carousel-container,
.carousel-cells {
    float: left;
    width: 100%;
    display: block;
}

.clear {
    float: left;
    width: 100%;
    display: block;
}
.float-none{
    float: none;
}
.body__widget{
    background-color: #4f4c43;
    border-radius: var(--low-radius);
    padding: 1.4rem;
    .widget-heading{
        font-size: 20px;
        color: color(white);
        @include fw(b);
    }
    .btn{
        .material-icons-outlined{
            font-size: 18px;
            margin-right: 7px;
        }
    }
    .first-review{
        margin-left: 20px;
        color: color(white);
        font-size: 16px;
        @include fw(b);
    }
}
.body__widget-2{
    background-color: #2c2c2c;
    padding: 1.4rem;
    border-radius: var(--low-radius);
    .theme__color-change{
        display: grid;
        grid-auto-flow: column;
        gap: 7px;
        span{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: var(--theme-color);
        }
    }
}
.card-1{
    box-shadow: var(--card-shadow);
    float: left;
    width: 100%;
    margin-bottom: 20px;

    .card-head {
        background-color: color(lightgrey);
        padding: 15px 25px;
        border-top-left-radius: var(--low-radius);
        border-top-right-radius: var(--low-radius);

        .heading {
            font-size: 20px;
            @include fw(b);
        }

        .entries {
            font-size: 14px;
            color: color(midlght-gray);
        }
    }

    .card-contentp-2 {
        background-color: color(white);
        width: 100%;
    }

    .card-content {
        float: left;
        background-color: color(white);
        width: 100%;
        border-bottom-left-radius: var(--low-radius);
        border-bottom-right-radius: var(--low-radius);

        .table-header {
            @include d-flex(r, sb, c);
            padding: 25px;

            &>.search {
                .form-element {
                    background-color: color(lightgrey);
                    border-radius: var(--low-radius);
                    position: relative;

                    .form-field {
                        padding: 12px 20px 12px 40px;
                    }

                    .material-icons-outlined {
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 20px;
                    }
                }
            }

            &>.filter {
                @include d-flex(r, sb, c);

                .btn {
                    margin: 0 0 0 10px;

                    .material-icons-outlined {
                        font-size: 22px;
                        margin-right: 5px;
                        pointer-events: none;
                    }
                }

                .dropdown {
                    position: relative;
                    display: inline-block;
                }

                .dropdown-content {
                    opacity: 0;
                    visibility: hidden;
                    transition: .3s;
                    position: absolute;
                    background-color: #f1f1f1;
                    min-width: 160px;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    z-index: 1;

                    h3 {
                        font-size: 18px;
                        border-bottom: 1px solid color(black);
                    }
                }

                .show {
                    opacity: 1;
                    visibility: visible;
                    transition: .3s;
                }
            }
        }

        .table-body {
            padding: 25px;
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
        }
        &.top--radius{
            border-top-left-radius: var(--low-radius);
            border-top-right-radius: var(--low-radius);
        }
    }

    .card-footer {
        background-color: #5a5959;
        padding: 0.8rem 2rem;
        float: left;
        width: 100%;
        border-bottom-left-radius: var(--low-radius);
        border-bottom-right-radius: var(--low-radius);

        p {
            color: white;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: end;

            span {
                background-color: #08b9b7;
                padding: 0.1rem 0.6rem;
                border-radius: 5px;
                margin-right: 10px;
            }
        }
    }
}

.card-2 {
    float: left;
    width: 100%;
    margin-bottom: 20px;

    label {
        margin-bottom: 15px;
        float: left;
        color: color(midlght-gray);
        @include d-flex(r, c, c);

        a {
            line-height: 0;

            .material-icons-round {
                margin-left: 6px;
                font-size: 20px;
            }
        }
    }

    .card-block {
        position: relative;
        border: 1px solid #838383;
        border-radius: var(--low-radius);
        padding: 15px;
        float: left;
        width: 100%;

        .h4 {
            font-size: 22px;
            margin-top: 10px;
            margin-bottom: 10px;
            @include fw(b);
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .h5 {
            @include fw(b);
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p {
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .card-action {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}
.card-3{
    position: relative;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    figure{
        width: 70px;
        height: 70px;
        position: absolute;
        left: 20px;
        top: -35px;
        img{
            border-radius: 50%;
            border: 6px solid #ebe2c7;
            overflow: hidden;
            background-color: white;
            width: 100%;
            // height: 100%;
            max-height: 100%;
        }
    }
    .profile__name{
        background-color: #ebebeb;
        margin-top: 4rem;
        padding: 0.8rem 1rem;
        .h2{
            font-size: 20px;
            @include fw(b);
        }
        .designation{
            font-size: 12px;
        }
    }
    .type{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.8rem 1rem 1.2rem;
        p{
            font-size: 12px;
            display: flex;
            align-items: center;
            .material-icons-two-tone{
                font-size: 16px;
                margin-right: 4px;
            }
        }
    }
    .tags{
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;
        padding: 0 1rem 0.6rem;
        span{
            font-size: 12px;
            background-color: #fbc011;
            padding: 0.2rem 0.6rem;
            text-align: center;
            border-radius: 1rem;
        }
        .more{
            font-size: 12px;
            padding: 0.2rem 0.6rem;
            text-align: center;
            border-radius: 1rem;
            background-color: #ebebeb !important;
            transition: .3s ease-in;
            &:hover{
                background-color: #888 !important;
                color: white;
                transition: .3s ease-in;
            }
        }
    }
    .card__description{
        padding: 0.8rem 1rem;
        h4{
            color: #444444;
            font-size: 16px;
            @include fw(m);
        }
        p{
            color: #444444;
            font-size: 13px;
        }
    }
    .actions{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.8rem 1rem;
        .material-icons-two-tone{
            font-size: 19px;
        }
        p{
            font-size: 14px;
            padding-left: 4px;
        }
        .setting{
            line-height: 0;
            .material-icons-two-tone{
                font-size: 22px !important;
                opacity: 0.6;
            }
            button{
                cursor: pointer;
            }
        }
    }
    .status{
        position: absolute;
        right: 0;
        top: 16px;
        display: flex;
        align-items: center;
        .bookmark{
            line-height: 0;
            margin-right: 10px;
            cursor: pointer;
        }
        .stat{
            font-size: 10px;
            font-weight: 700;
            padding: 6px 12px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            color: white;
        }
    }
    .published{
        .status{
            .stat{
                background-color: #0e9b04;
            }
        }
    }
    .review{
        .status{
            .stat{
                background-color: #db8310;
            }
        }
    }
    .rejected{
        .status{
            .stat{
                background-color: #f21111;
            }
        }
    }
}
.dropdown-menu-2{
    list-style-type: none;
    width: 120px;
    position: absolute;
    z-index: 9;
    top: -128px;
    right: -18px;
    background-color: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #d8d8d8;
    padding: 0.8rem;
    border-radius: 10px;
    display: grid;
    grid-gap: 6px;
    gap: 2px;
    li{
        a{
            position: relative;
            &:after{
                content: '';
                width: 0%;
                height: 1px;
                background-color: black;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: .5s ease-in;
            }
            &:hover{
                &:after{
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: black;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: .5s ease-in;
                }
            }
        }
    }
    .dropdown-item{
        font-size: 12px;
    }
}
.hide-widget,
.show-widget {
    padding: 4px 15px;
    border-radius: 30px;
    font-size: 14px;
    margin-bottom: 15px;
    background-color: var(--bgClr);
    border: 1px solid var(--borderClr);
}

.show-widget {
    --bgClr: #bef3b7;
    --borderClr: #27a700;
}

.hide-widget {
    --bgClr: #fbdcdc;
    --borderClr: #e76565;
}

.gap-b {
    margin-bottom: 1rem !important;
}

.tag {
    display: flex;
    flex-wrap: wrap;
    &>span {
        position: relative;
        margin: 0;
        padding: 4px 15px 4px 15px;
        font-size: 14px;
        max-width: 100%;
        background-color: color(yellow);
        background-size: 100% 19px;
        background-repeat: repeat-x;
        background-clip: padding-box;
        color: #000;
        cursor: default;
        border-radius: 30px;
        margin: 0 5px 5px 0;
    }
}

.tag-2 {
    display: flex;
    flex-wrap: wrap;

    &>span {
        position: relative;
        margin: 0;
        padding: 2px 10px 2px 10px;
        font-size: 14px;
        max-width: 100%;
        background-color: #fce39a;
        background-size: 100% 19px;
        background-repeat: repeat-x;
        background-clip: padding-box;
        color: #000;
        cursor: default;
        border-radius: 5px;
        margin: 0 5px 5px 0;
    }

    .more {
        background-color: color(white);
        border: 1px solid #565656;
        color: #2d2d2d;
        cursor: pointer;
    }

    &.large-font {
        &>span {
            font-size: 18px;
            padding: 2px 15px 2px 15px;
            margin: 0 10px 10px 0;
        }
    }
}

.tag-3 {
    &>div {
        position: relative;
        margin: 0;
        padding: 4px 35px 4px 15px;
        font-size: 14px;
        max-width: 100%;
        background-color: color(yellow);
        background-size: 100% 19px;
        background-repeat: repeat-x;
        background-clip: padding-box;
        color: #000;
        cursor: default;
        border-radius: 30px;
        margin: 0 2px 5px 0;
        display: inline-block;

        .search-choice-close {
            position: absolute;
            top: 4px;
            right: 8px;
            display: block;

            &:after {
                content: "\e5cd";
                font-family: "Material Icons Round";
                font-weight: normal;
                font-style: normal;
            }
        }
    }
}

.w-200px {
    width: 200px;

    @media (max-width: 1700px) {
        width: auto;
    }
}

.w-115px {
    width: 115px;
}

.dropdown-menu {
    position: relative;

    .menu-btn {
        cursor: pointer;
    }

    .menu-content {
        position: absolute;
        top: 102%;
        right: 0;
        background-color: #fff;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, .1);
        padding: 1em;
        border-radius: 10px;
        // display: none;
        width: max-content;
        z-index: 9;

        .links {
            position: relative;
            padding: 5px 0;
            display: block;
            font-size: 14px;
        }

        &.menu-content-show {
            display: block !important;
        }
    }
}

// Switch Design //

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
    margin: 0 14px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked+.slider {
            background-color: #e0eed0;

            &:before {
                -webkit-transform: translateX(34px);
                -ms-transform: translateX(34px);
                transform: translateX(34px);
                background-color: color(approved);
            }
        }

        &:focus+.slider {
            box-shadow: 0 0 1px #e0eed0;
        }
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fde8df;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 3px;
        background-color: color(orange);
        -webkit-transition: .4s;
        transition: .4s;
    }

    &.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }
}

.card--title-italic {
    font-size: 18x;
    font-style: italic;
    @include fw(m);
}

.code-text {
    background: #f0f0f0;
    padding: 20px;
    color: #1a1a1a;
    margin: 15px 0;
    border-radius: 10px;
    float: left;
    width: 100%;

    code {
        max-height: 300px;
        overflow-y: auto;
        display: block;
    }
}

.grid-card-content {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    gap: 1.5rem;
}

.flex-card-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.author--profile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 5px;

    .author-overlay {
        position: relative;

        figure {
            width: 65px;
            height: 65px;
            flex: 0 0 65px;
            border-radius: 50%;
            background-color: #fff;
            border: 2px solid #d9d9d9;
            position: relative;
            overflow: hidden;
            margin-right: 1rem;

            img {
                position: absolute;
                left: 0;
                width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
    }

    .pic-upload {
        position: absolute;
        top: 52%;
        left: 19px;
        background: #fff;
        padding: 5px;
        pointer-events: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        line-height: 20px;

        .material-icons-outlined {
            font-size: 14px;
        }
    }

    p {
        font-size: 12px;

        &:first-of-type {
            color: #696969;
        }
    }
}

.edituser_pop{
    .__top{
        display: grid;
        grid-template-columns: 550px 180px;
        gap: 3rem;
        place-content: space-between;
    }
    .__nameuser{
        display: grid;
        grid-template-columns: 130px auto;
        gap: 1.5rem;
    }
    .form-element{
        margin-bottom: 15px;
        .form-label{
            position: relative;
            top: 0;
            left: 0;
            margin-bottom: 6px;
        }
        &.has-value{
            .form-label{
                position: relative;
                top: 0;
                left: 0;
                margin-bottom: 6px;
                padding: 0;
            }
        }
    }
    .card-1 {
        .card-head {
            background-color: #eaeaea;
            padding: 10px 18px;
            align-items: center;
            .heading {
                font-size: 16px;
                font-weight: 600 !important;
            }
            .material-icons{
                font-size: 17px;
            }
        }
        .card-content{
            border: 1px solid #eaeaea;
            padding: 18px;
        }
    }
    .multiselect-dropdown{
        background: transparent;
        border: none;
        padding: 0;
        min-height: auto;
    }
}

.author--profile-large {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 5px;

    .author-overlay {
        position: relative;

        figure {
            width: 120px;
            height: 120px;
            flex: 0 0 120px;
            border-radius: 50%;
            background-color: #fff;
            border: 2px solid #d9d9d9;
            position: relative;
            overflow: hidden;
            margin-right: 1rem;

            img {
                position: absolute;
                left: 0;
                width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
    }

    .pic-upload {
        position: absolute;
        top: 52%;
        left: 19px;
        background: #fff;
        padding: 5px;
        pointer-events: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        line-height: 20px;

        .material-icons-outlined {
            font-size: 14px;
        }
    }

    p {
        font-size: 12px;

        &:first-of-type {
            color: #696969;
        }
    }
}

.font--tiny {
    font-size: 12px;
}

.text-up {
    text-transform: uppercase;
}

// File Upload //@at-root
// .box-tools {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
//   .box-tools .remove-preview{
//     background: color(yellow);
//     width: 25px;
//     height: 25px;
//     border-radius: 50%;
//     position: absolute;
//     right: 20px;
//     top: 20px;
//     cursor: pointer;
//     box-shadow: 0 0 10px 5px rgba(0,0,0,0.6) !important;
//     .material-icons-round{
//         font-size: 16px;
//         line-height: 24px;
//     }
//   }

//   .dropzone-wrapper {
//     border: 2px dashed #91b0b3;
//     color: #92b0b3;
//     position: relative;
//     background-color: color(white);
//     height: 60px;
//   }

//   .dropzone-desc {
//     position: absolute;
//     margin: 0 auto;
//     left: 0;
//     right: 0;
//     text-align: center;
//     top: 50%;
//     transform: translateY(-50%);
//     font-size: 16px;
//   }

//   .dropzone,
//   .dropzone:focus {
//     position: absolute;
//     outline: none !important;
//     width: 100%;
//     height: 60px;
//     cursor: pointer;
//     opacity: 0;
//   }

//   .dropzone-wrapper:hover,
//   .dropzone-wrapper.dragover {
//     background: #ecf0f5;
//   }

//   .preview-zone {
//     text-align: center;
//   }

//   .preview-zone .box {
//     box-shadow: none;
//     border-radius: 0;
//     margin-bottom: 0;
//   }
//   .preview-zone .box .box-body img{
//     width: 100%;
//   }

#modal {
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #5F5F5F;
    opacity: 0.95;
    display: none;
}

#preview {
    z-index: 11;
    position: fixed;
    top: 0px;
    left: 0px;
    display: none;
    border: 4px solid #A5A2A2;
    border-radius: 4px;
    float: left;
    font-size: 0px;
    line-height: 0px;
}

#preview .buttons {
    width: 36px;
    position: absolute;
    bottom: 0px;
    right: -44px;
}

#preview .buttons .ok {
    border: 4px solid #F5F5F5;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    line-height: 0px;
    font-size: 0px;
    background-image: url('./../../img/Ok.png');
    background-size: 20px;
    background-repeat: no-repeat;
}

#preview .buttons .ok:hover {
    background-image: url('./../../img/OkGreen.png');
    background-size: 20px;
}

#preview .buttons .cancel {
    margin-bottom: 4px;
    border: 4px solid #F5F5F5;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    line-height: 0px;
    font-size: 0px;
    background-image: url('./../../img/Cancel.png');
    background-size: 20px;
    background-repeat: no-repeat;
}

#preview .buttons .cancel:hover {
    background-image: url('./../../img/CancelRed.png');
    background-size: 20px;
}

.simple-cropper-images {
    width: 820px;
    margin: 0 auto 20px;

}

.remove-image {
    .cropme {
        img {
            display: none;
        }
    }
}

.selected-image {
    width: 100%;
    height: 354px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.upload-info {
    position: relative;
    float: left;
    width: 100%;
    padding: 10px;
    border: 2px dashed #91b0b3;
    color: #92b0b3;
    text-align: center;
    border-radius: 6px;
    margin-top: 10px;

    input[type="file"] {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        opacity: 0;
        cursor: pointer;
    }
}

.cropme {
    float: left;
    background-color: #f1f1f1;
    margin-bottom: 5px;
    margin-right: 5px;
    background-image: url('./../../img/UploadLight.png');
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.cropme:hover {
    background-image: url('./../../img/UploadDark.png');
}

.jcrop-holder {
    direction: ltr;
    text-align: left;
    -ms-touch-action: none
}

.jcrop-hline,
.jcrop-vline {
    background: #fff;
    font-size: 0;
    position: absolute
}

.jcrop-vline {
    height: 100%;
    width: 1px !important
}

.jcrop-vline.right {
    right: 0
}

.jcrop-hline {
    height: 1px !important;
    width: 100%
}

.jcrop-hline.bottom {
    bottom: 0
}

.jcrop-tracker {
    height: 100%;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none
}

.jcrop-handle {
    background-color: #333;
    border: 1px #eee solid;
    width: 7px;
    height: 7px;
    font-size: 1px
}

.jcrop-handle.ord-n {
    left: 50%;
    margin-left: -4px;
    margin-top: -4px;
    top: 0
}

.jcrop-handle.ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -4px;
    margin-left: -4px
}

.jcrop-handle.ord-e {
    margin-right: -4px;
    margin-top: -4px;
    right: 0;
    top: 50%
}

.jcrop-handle.ord-w {
    left: 0;
    margin-left: -4px;
    margin-top: -4px;
    top: 50%
}

.jcrop-handle.ord-nw {
    left: 0;
    margin-left: -4px;
    margin-top: -4px;
    top: 0
}

.jcrop-handle.ord-ne {
    margin-right: -4px;
    margin-top: -4px;
    right: 0;
    top: 0
}

.jcrop-handle.ord-se {
    bottom: 0;
    margin-bottom: -4px;
    margin-right: -4px;
    right: 0
}

.jcrop-handle.ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -4px;
    margin-left: -4px
}

.jcrop-dragbar.ord-n,
.jcrop-dragbar.ord-s {
    height: 7px;
    width: 100%
}

.jcrop-dragbar.ord-e,
.jcrop-dragbar.ord-w {
    height: 100%;
    width: 7px
}

.jcrop-dragbar.ord-n {
    margin-top: -4px
}

.jcrop-dragbar.ord-s {
    bottom: 0;
    margin-bottom: -4px
}

.jcrop-dragbar.ord-e {
    margin-right: -4px;
    right: 0
}

.jcrop-dragbar.ord-w {
    margin-left: -4px
}

.jcrop-light .jcrop-hline,
.jcrop-light .jcrop-vline {
    background: #fff;
    filter: alpha(opacity=70) !important;
    opacity: .7 !important
}

.jcrop-light .jcrop-handle {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background-color: #000;
    border-color: #fff;
    border-radius: 3px
}

.jcrop-dark .jcrop-hline,
.jcrop-dark .jcrop-vline {
    background: #000;
    filter: alpha(opacity=70) !important;
    opacity: .7 !important
}

.jcrop-dark .jcrop-handle {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background-color: #fff;
    border-color: #000;
    border-radius: 3px
}

.solid-line .jcrop-hline,
.solid-line .jcrop-vline {
    background: #fff
}

.jcrop-holder img,
img.jcrop-preview {
    max-width: none
}

// Tab-1 //

.tab-1 {
    list-style: none;
    @include d-flex(r);

    li {
        margin: 0 0 0 25px;

        a {
            font-size: 16px;
            position: relative;

            &:hover {
                &:after {
                    content: '';
                    background-color: color(black);
                    height: 1px;
                    width: 100%;
                    display: block;
                    margin-top: 5px;
                }
            }
        }

        &.active {
            a {
                &:after {
                    content: '';
                    background-color: color(black);
                    height: 1px;
                    width: 100%;
                    display: block;
                    margin-top: 5px;
                }
            }
        }
    }
}

.tab-content {
    padding: 15px;

    &>div {
        display: none;
    }

    .active {
        display: block;
    }
}

.chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chosen-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.chosen-container .chosen-drop {
    position: absolute;
    top: 100%;
    z-index: 1010;
    width: 100%;
    border: 1px solid #aaa;
    border-top: 0;
    background: #fff;
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    clip: rect(0, 0, 0, 0);
    -webkit-clip-path: inset(100% 100%);
    clip-path: inset(100% 100%);
}

.chosen-container.chosen-with-drop .chosen-drop {
    clip: auto;
    -webkit-clip-path: none;
    clip-path: none;
}

.chosen-container a {
    cursor: pointer;
}

.chosen-container .search-choice .group-name,
.chosen-container .chosen-single .group-name {
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
    color: #999999;
}

.chosen-container .search-choice .group-name:after,
.chosen-container .chosen-single .group-name:after {
    content: ":";
    padding-left: 2px;
    vertical-align: top;
}

.chosen-container .chosen-results {
    color: #444;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 4px 4px 0;
    padding: 0 0 0 4px;
    height: 180px;
    -webkit-overflow-scrolling: touch;
}

.chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 5px 6px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none;
}

.chosen-container .chosen-results li.active-result {
    display: list-item;
    cursor: pointer;
}

.chosen-container .chosen-results li.disabled-result {
    display: list-item;
    color: #ccc;
    cursor: default;
}

.chosen-container .chosen-results li.highlighted {
    background-color: color(yellow);
    color: black;
}

.chosen-container .chosen-results li.no-results {
    color: #777;
    display: list-item;
    background: #f4f4f4;
}

.chosen-container .chosen-results li.group-result {
    display: list-item;
    font-weight: bold;
    cursor: default;
}

.chosen-container .chosen-results li.group-option {
    padding-left: 15px;
}

.chosen-container .chosen-results li em {
    font-style: normal;
    text-decoration: underline;
}

.chosen-container-multi .chosen-choices {
    width: 100%;
    min-height: 50px;
    font-weight: inherit;
    text-align: left;
    padding: 8px 15px;
    background: #fff;
    resize: none;
    color: #000;
    border-radius: 5px;
    border: 1px solid #2f2f2f;
    height: auto;
    position: relative;
    overflow: hidden;
    cursor: text;
}

.chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none;
}

.chosen-container-multi .chosen-choices li.search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    margin: 1px 0;
    padding: 0;
    height: 25px;
    outline: 0;
    border: 0 !important;
    background: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #999;
    font-size: 100%;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0;
    width: 25px;
}

.chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 0;
    padding: 6px 20px 6px 15px;
    max-width: 100%;
    background-color: color(yellow);
    background-size: 100% 19px;
    background-repeat: repeat-x;
    background-clip: padding-box;
    color: #000;
    cursor: default;
    border-radius: 30px;
    margin: 0 5px 5px 0;
}

.chosen-container-multi .chosen-choices li.search-choice span {
    word-wrap: break-word;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    position: absolute;
    top: 6px;
    right: 5px;
    display: block;

    &:after {
        content: '\e5cd';
        font-family: 'Material Icons Round';
        font-weight: normal;
        font-style: normal;
    }
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
    background-position: -42px -10px;
}

.chosen-container-multi .chosen-choices li.search-choice-disabled {
    padding-right: 5px;
    border: 1px solid #ccc;
    background-color: #e4e4e4;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
    background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    color: #666;
}

.chosen-container-multi .chosen-choices li.search-choice-focus {
    background: #d4d4d4;
}

.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
    background-position: -42px -10px;
}

.chosen-container-multi .chosen-results {
    margin: 0;
    padding: 0;
}

.chosen-container-multi .chosen-drop .result-selected {
    display: list-item;
    color: #ccc;
    cursor: default;
}

// Breadcrumbs //

.breadcrumbs {
    a {
        font-size: 14px;
        @include fw(b);
        color: color(hvy-darkgray);
        margin: 0 2px 0 0;

        &.current {
            color: color(midlght-gray);
            @include fw(r);
        }

        &:not(:last-child):after {
            content: '/';
            display: inline-block;
            margin-left: 5px;
        }
    }
}

section[class*="bg--"]:not(.p-0):not(.paddingYx) {
    margin-top: var(--spacer);
    padding-top: var(--spacer);
    padding-bottom: var(--spacer);
}

section:not([class*="bg--"]):not(.p-0):not(.fact__inner-hero) {
    padding-top: var(--spacer);
}

section.marginY {
    margin-top: var(--spacer);
    margin-bottom: var(--spacer);
}

section.marginT {
    margin-top: var(--spacer);
}

.paddingY {
    padding-top: var(--spacer);
    padding-bottom: var(--spacer);
}

.paddingB {
    padding-bottom: var(--spacer);
}

.marginT {
    margin-top: var(--spacer);
}

.marginB {
    margin-bottom: var(--spacer);
}

.paddingYx {
    padding: var(--spacerHalf) 0;
}

// main > section:nth-child(1){
//     --space-modifier: 40px;
//     padding-top: var(--space-modifier) !important;
//     &.marginT{
//         margin-top: var(--space-modifier) !important;
//     }
//     &.paddingB{
//         padding-bottom: var(--space-modifier) !important;
//     }
// }

.shadow-1,
.hvr\:shadow-1 {
    margin-bottom: 25px;
}

.hvr\:shadow-1 {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.01);
    transition: box-shadow 0.3s ease;
}

.shadow-1,
.hvr\:shadow-1:hover {
    box-shadow: 0px 0px 21.6px 2.4px rgba(0, 0, 0, 0.11);
}

.shadow-2 {
    box-shadow: 4px 3px 15.04px 0.96px rgba(0, 0, 0, 0.04);
}

.non-hvr\:grayscale:not(:hover) img {
    filter: grayscale(100%);
}

.uuid-tag {
    border-radius: 8px;
    position: relative;
    margin-bottom: 10px;

    p {
        span {
            display: block;
        }
    }

    .close {
        cursor: pointer;
        position: absolute;
        right: -6px;
        top: -6px;

        .material-icons-outlined {
            font-size: 18px;
        }
    }
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.grid-3--expanded {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    @include clamp('row-gap', 25, 75);
    @include clamp('column-gap', 25, 150);
}

.grid-1 {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0 25px;
    grid-gap: 0 25px;

    &.bordered-btm {
        row-gap: 0;

        &>* {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        &>*:not(:nth-last-child(-n+2)) {
            border-bottom: 1px solid color(border);
        }
    }
}


.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0 25px;
    grid-gap: 0 25px;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0 25px;
    grid-gap: 0 25px;
}

.grid-5 {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 0 25px;
    grid-gap: 0 25px;
}

.grid-6 {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 0 25px;
    grid-gap: 0 25px;
}

.grid-2-40-half {
    display: grid;
    grid-template-columns: 40% 40%;
    gap: 25px;
    grid-gap: 25px;
}

.grid-2-20-80 {
    display: grid;
    grid-template-columns: 20% 78%;
    gap: 25px;
    grid-gap: 25px;
}
.grid-2-70-30{
    display: grid;
    grid-template-columns: 70% 28%;
    gap: 15px;
    grid-gap: 15px;
}
.grid-2-30-70{
    display: grid;
    grid-template-columns: 28% 70%;
    gap: 15px;
    grid-gap: 15px;
}
.grid-2-60-40{
    display: grid;
    grid-template-columns: 60% 38%;
    gap: 15px;
    grid-gap: 15px;
}
.grid-gap{
    gap: var(--g-gap);
}
.grid-column{
    grid-template-columns: var(--g-column);
}
.ng-scroll-viewport{
    overflow-y: auto;
    scrollbar-width: auto !important;
}
.tag--body{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &>div {
        border: 1px solid #8a8a8a;
        border-radius: 40px;
        padding: 6px 15px;
        font-size: 16px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        transition: .3s;

        span {
            padding-left: 10px;
            cursor: pointer;
        }

        &:hover {
            background-color: color(yellow);
            border: 1px solid color(yellow);
            color: color(black);
            transition: .3s;
        }
    }
}
.big--font{
    font-size: 22px;
}
.carousel-arrows{
    .carousel-arrow-prev{
        position: absolute !important;
        right: 55px !important;
        top: -47px !important;
        left: auto !important;
        background-color: #fbc011 !important;
    }

    .carousel-arrow-next {
        position: absolute !important;
        right: 0px !important;
        top: -47px !important;
        left: auto !important;
        background-color: #fbc011 !important;
    }
}

.carousel-cell {
    .layer {
        img {
            border: 4px solid #aec8df;
        }
    }
}

.count-block {
    border-radius: 10px;
    padding: 1rem 1rem;
    margin-bottom: 1.5rem;

    &.light {
        background-color: #ffefc1;

        .progress-bar {
            height: 10px;
            width: 100%;
            background-color: #edd9a2;
            border-radius: 30px;
            overflow: hidden;

            .progress {
                background-color: #b78b0d;
                height: 100%;
            }
        }
    }

    &.dark {
        background-color: #fbc011;

        .progress-bar {
            height: 10px;
            width: 100%;
            background-color: #edd9a2;
            border-radius: 30px;
            overflow: hidden;

            .progress {
                background-color: #98750d;
                height: 100%;
            }
        }
    }

    h5 {
        font-size: 18px;
        color: color(black);
    }

    .count-numbers {
        font-size: 30px;
        font-weight: bold;
        text-align: right;
    }

    .heading {
        display: flex;
        justify-content: space-between;
    }
}

.numeric--list {
    list-style: none;
    counter-reset: my-sec-counter;

    li {
        padding: 12px 0;
        grid-template-columns: 25px 40% auto;
        display: grid;

        &:not(:last-child) {
            border-bottom: 1px solid #e5e5e5;
        }

        &:before {
            counter-increment: my-sec-counter;
            content: counter(my-sec-counter) ". ";
        }

        span {
            word-break: break-word;

            &:first-child {
                font-weight: bold;
                color: color(black);
                font-size: 16px;
            }

            &:last-child {
                font-size: 14px;
                color: #6c6c6c;
                text-align: right;
            }
        }
    }
}

.numeric--list-2 {
    list-style: none;
    counter-reset: my-sec-counter;

    li {
        padding: 3px 0;
        font-weight: bold;

        &:before {
            counter-increment: my-sec-counter;
            content: counter(my-sec-counter) ". ";
        }
    }
}

// .grid-4{
//     display: grid;
//     grid-template-columns: repeat(auto-fill, minmax(var(--g4-width, 235px), 1fr));
//     @include clamp('gap', 25, 40);

//     @media(min-width: 1400px){
//         --g4-width: 300px;
//     }
// }

// -----------------------------------------------

$startDesktopMenu: 768px;

.ui-dropdown {
    position: relative;

    span,
    a {
        display: inline-block;
    }

    .ui-dropdown--selected {
        display: inline-flex;
        align-items: center;
        background-color: #f5f7fa;
        padding: 10px 35px 10px 18px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
        font-size: 14px;
        width: 105px;

        &::after {
            content: '';
            display: inline-block;
            width: 0.4em;
            height: 0.4em;
            transform: rotate(45deg);
            border-style: solid;
            border-color: currentColor;
            border-width: 0 1px 1px 0;
            transform-origin: 0.4em;
            position: absolute;
            right: 1.25em;
            top: 1.30em;
        }
    }

    .ui-dropdown--items {
        display: inline-grid;
        z-index: 9;
        gap: 12px;
        position: absolute;
        top: 102%;
        left: 0;
        background-color: color(white);
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
        padding: 0.8rem 1.4em;
        // width: 140px;
        visibility: hidden;
        border-radius: 10px;
        width: max-content;
        width: 100%;

        &>a {
            cursor: pointer;
            font-size: 14px;

            &::before {
                content: attr(data-val);
            }

            &:hover {
                color: color(dgrn);
            }
        }
    }

    &.is-open {
        .ui-dropdown--items {
            visibility: visible;
        }
    }

    &.filter {
        .ui-dropdown--selected {
            background-color: color(yellow);
            padding: 10px 20px;
            font-size: 1.05rem;

            &::after {
                display: none;
            }
        }

        .ui-dropdown--items {
            padding: 0;
            right: 0;
            min-width: 300px;

            .title {
                padding: 12px 20px;
                border-bottom: 1px solid #ccc;
                font-size: 18px;
            }

            .filter-box {
                padding: 12px 20px;
                display: grid;

                .form-element {
                    position: relative;

                    .form-field {
                        padding: 12px;
                        background-color: #f5f7fa;
                        border-radius: var(--low-radius);
                    }
                }
            }
        }
    }
}


.ui-filters {
    display: grid;
    grid-auto-flow: column;
    gap: 1.5em;
    font-size: toRem(18);

    &>a {
        display: inline-block;
        padding-bottom: 0.2em;
        color: color(light-txt);

        &::after {
            content: '';
            display: block;
            width: 2ch;
            height: 2px;
            background-color: currentColor;
            transform: scale(0);
            transform-origin: left bottom;
        }

        &:hover {
            color: color(lgrn);
        }

        &.actv {
            color: color(dgrn);
            font-weight: 500;

            &::after {
                transform: scale(1);
            }
        }
    }
}


@keyframes heartfill-anim {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.2);
    }

    100% {
        transform: scale(1);
    }
}


.link-arw {
    transform: translateX(-10px);
    transition: 0.2s;

    img {
        width: 1.25em;
        transition: inherit;
    }

    &:hover {
        img {
            transform: translateX(10px);
        }
    }
}

.max_width_100 {
    max-width: 100% !important;
}

.pagination {
    float: left;
    width: 100%;
    margin-bottom: toRem(100);

    &>ul {
        list-style: none;
        @include d-flex(r, fe, c);

        &>li {
            margin: 0 2px;
            display: flex;

            &>a {
                text-align: center;
                // width: toRem(35);
                // height: toRem(35);
                // line-height: toRem(35);
                padding: 8px 15px;
                border-radius: 10px;
                transition: .3s;

                &:hover {
                    background-color: color(yellow);
                    color: color(black);
                    transition: .3s;
                }
            }

            &.not-number {
                pointer-events: none;
            }

            &.active {
                a {
                    background-color: color(yellow);
                    color: color(black);
                }
            }

            &.arrow-left {
                line-height: 2;
                padding-right: toRem(10);
                display: block;

                a {
                    background-color: transparent;
                    border: none;
                    padding: toRem(10);

                    img {
                        transition: .3s;
                        width: 32px;
                        filter: grayscale(1) brightness(0);
                    }

                    &:hover {
                        img {
                            transform: translateX(-5px);
                            transition: .3s;
                            filter: grayscale(0);
                        }
                    }

                    .material-icons-outlined {
                        line-height: 2;
                    }
                }
            }

            &.disabled-item {
                a {
                    pointer-events: none;
                    color: #ccc;
                }
            }

            &.arrow-right {
                line-height: 2;
                padding-left: toRem(10);
                display: block;

                a {
                    background-color: transparent;
                    border: none;
                    padding: toRem(10);

                    img {
                        transition: .3s;
                        width: 32px;
                        filter: grayscale(1) brightness(0);
                    }

                    &:hover {
                        img {
                            transform: translateX(5px);
                            transition: .3s;
                            filter: grayscale(0);
                        }
                    }

                    .material-icons-outlined {
                        line-height: 2;
                    }
                }
            }

            &.disabled-item {
                a {
                    pointer-events: none;
                    color: #ccc;
                }
            }

            &.more-numbers {
                a {
                    border-radius: 10px;
                    width: 2.8rem;
                }
            }

            &.extend {
                a {
                    line-height: 1.6;
                }
            }
        }
    }
}

.table {
    // width:100%;
    width: 1680px;
    // overflow-x: auto;
    // overflow-y: hidden;
    margin-bottom: 1rem;
    background-color: transparent;

    tr {
        &:not(:last-child) {
            border-bottom: 1px solid #dee2e6;
        }

        &.halt {
            td {
                &:first-child {
                    width: 70px;
                    position: relative;

                    &:after {
                        content: '';
                        width: 15px;
                        height: 15px;
                        display: block;
                        border-radius: 50%;
                        background-color: color(pending);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:hover {
                        &:before {
                            content: "Halt";
                            background-color: color(pending);
                            padding: 2px 15px;
                            color: #fff;
                            display: block;
                            position: absolute;
                            width: max-content;
                            text-align: center;
                            top: 0;
                            font-size: 14px;
                            height: 25px;
                            left: 70%;
                            border-radius: 5px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }

        &.resume {
            td {
                &:first-child {
                    width: 70px;
                    position: relative;

                    &:after {
                        content: '';
                        width: 15px;
                        height: 15px;
                        display: block;
                        border-radius: 50%;
                        background-color: color(approved);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:hover {
                        &:before {
                            content: "Resume";
                            background-color: color(approved);
                            padding: 2px 15px;
                            color: #fff;
                            display: block;
                            position: absolute;
                            width: max-content;
                            text-align: center;
                            top: 0;
                            font-size: 14px;
                            height: 25px;
                            left: 70%;
                            border-radius: 5px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }

    th {
        vertical-align: middle;
        border-bottom: 2px solid #dee2e6;
        padding: 10px 15px;
        text-align: inherit;
        font-size: 14px;
    }

    .published {
        color: #08a408;
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            border-radius: 50%;
            background-color: #08a408;
            position: absolute;
            top: 14px;
        }

        &>span {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .pending {
        color: #e48e14;
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            border-radius: 50%;
            background-color: #e48e14;
            position: absolute;
            top: 14px;
        }

        &>span {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .blocked {
        color: #da1010;
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            border-radius: 50%;
            background-color: #da1010;
            position: absolute;
            top: 14px;
        }

        &>span {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    td {
        vertical-align: middle;
        padding: 10px 15px;
        text-align: inherit;
        font-size: 14px;
        word-break: break-word;

        &>a {
            position: relative;
            display: inline-block;

            &:after {
                content: '';
                background-color: color(black);
                width: 100%;
                height: 1px;
                display: block;
                transition: .3s ease-in-out;
            }

            &:hover {
                &:after {
                    content: '';
                    background-color: color(orange);
                    width: 80%;
                    height: 1px;
                    display: block;
                    transition: .3s ease-in-out;
                }
            }
        }

        .engage {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;
            grid-gap: 5px;

            .material-icons-two-tone {
                font-size: 20px;
                padding-right: 5px;
                opacity: 0.6;
            }
        }
    }

    .vertical-align-top {
        td {
            vertical-align: top;
        }
    }

    .btn-group {
        display: inline-flex;
        align-items: center;
        background-color: #f5f7fa;
        padding: 5px 25px 5px 0px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
        font-size: 14px;
        width: 115px;
        position: relative;

        &:after {
            content: "";
            display: inline-block;
            width: 0.4em;
            height: 0.4em;
            transform: rotate(45deg);
            border-style: solid;
            border-color: currentColor;
            border-width: 0 1px 1px 0;
            transform-origin: 0.4em;
            position: absolute;
            right: 1.25em;
            top: 1.6em;
        }
    }
}

table {
    tr {
        &:not(:last-child) {
            border-bottom: 1px solid #dee2e6;
        }

        &.halt {
            td {
                &:first-child {
                    width: 70px;
                    position: relative;

                    &:after {
                        content: '';
                        width: 15px;
                        height: 15px;
                        display: block;
                        border-radius: 50%;
                        background-color: color(pending);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:hover {
                        &:before {
                            content: "Halt";
                            background-color: color(pending);
                            padding: 2px 15px;
                            color: #fff;
                            display: block;
                            position: absolute;
                            width: max-content;
                            text-align: center;
                            top: 0;
                            font-size: 14px;
                            height: 25px;
                            left: 70%;
                            border-radius: 5px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }

        &.resume {
            td {
                &:first-child {
                    width: 70px;
                    position: relative;

                    &:after {
                        content: '';
                        width: 15px;
                        height: 15px;
                        display: block;
                        border-radius: 50%;
                        background-color: color(approved);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:hover {
                        &:before {
                            content: "Resume";
                            background-color: color(approved);
                            padding: 2px 15px;
                            color: #fff;
                            display: block;
                            position: absolute;
                            width: max-content;
                            text-align: center;
                            top: 0;
                            font-size: 14px;
                            height: 25px;
                            left: 70%;
                            border-radius: 5px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }

    th {
        vertical-align: middle;
        border-bottom: 2px solid #dee2e6;
        padding: 10px 15px;
        text-align: inherit;
        font-size: 14px;
    }

    .published {
        color: #08a408;
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            border-radius: 50%;
            background-color: #08a408;
            position: absolute;
            top: 14px;
        }

        &>span {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .pending {
        color: #e48e14;
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            border-radius: 50%;
            background-color: #e48e14;
            position: absolute;
            top: 14px;
        }

        &>span {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .blocked {
        color: #da1010;
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            border-radius: 50%;
            background-color: #da1010;
            position: absolute;
            top: 14px;
        }

        &>span {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    td {
        vertical-align: middle;
        padding: 10px 15px;
        text-align: inherit;
        font-size: 14px;
        word-break: break-word;

        &>a {
            position: relative;
            display: inline-block;

            &:after {
                content: '';
                background-color: color(black);
                width: 100%;
                height: 1px;
                display: block;
                transition: .3s ease-in-out;
            }

            &:hover {
                &:after {
                    content: '';
                    background-color: color(orange);
                    width: 80%;
                    height: 1px;
                    display: block;
                    transition: .3s ease-in-out;
                }
            }
        }

        .engage {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;
            grid-gap: 5px;

            .material-icons-two-tone {
                font-size: 20px;
                padding-right: 5px;
                opacity: 0.6;
            }
        }
    }

    .vertical-align-top {
        td {
            vertical-align: top;
        }
    }

    .btn-group {
        display: inline-flex;
        align-items: center;
        background-color: #f5f7fa;
        padding: 5px 25px 5px 0px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
        font-size: 14px;
        width: 115px;
        position: relative;

        &:after {
            content: "";
            display: inline-block;
            width: 0.4em;
            height: 0.4em;
            transform: rotate(45deg);
            border-style: solid;
            border-color: currentColor;
            border-width: 0 1px 1px 0;
            transform-origin: 0.4em;
            position: absolute;
            right: 1.25em;
            top: 1.6em;
        }
    }
}

bs-dropdown-container {
    .dropdown-menu {
        list-style-type: none;
        z-index: 9;
        background-color: #ffffff;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
        padding: 0.8rem 1.4em;
        border-radius: 10px;
        display: grid;
        gap: 6px;
        width: auto;

        .dropdown-item {
            font-size: 14px;
        }
    }
}

.small-table {
    width: 100%;
    // overflow-x: auto;
    // overflow-y: hidden;
    margin-bottom: 1rem;
    background-color: transparent;

    tr {
        &:not(:last-child) {
            border-bottom: 1px solid #dee2e6;
        }

        &.halt {
            td {
                &:first-child {
                    width: 70px;
                    position: relative;

                    &:after {
                        content: '';
                        width: 15px;
                        height: 15px;
                        display: block;
                        border-radius: 50%;
                        background-color: color(pending);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:hover {
                        &:before {
                            content: "Halt";
                            background-color: color(pending);
                            padding: 2px 15px;
                            color: #fff;
                            display: block;
                            position: absolute;
                            width: max-content;
                            text-align: center;
                            top: 0;
                            font-size: 14px;
                            height: 25px;
                            left: 70%;
                            border-radius: 5px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }

        &.resume {
            td {
                &:first-child {
                    width: 70px;
                    position: relative;

                    &:after {
                        content: '';
                        width: 15px;
                        height: 15px;
                        display: block;
                        border-radius: 50%;
                        background-color: color(approved);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:hover {
                        &:before {
                            content: "Resume";
                            background-color: color(approved);
                            padding: 2px 15px;
                            color: #fff;
                            display: block;
                            position: absolute;
                            width: max-content;
                            text-align: center;
                            top: 0;
                            font-size: 14px;
                            height: 25px;
                            left: 70%;
                            border-radius: 5px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }

    th {
        vertical-align: middle;
        border-bottom: 2px solid #dee2e6;
        padding: 10px 15px;
        text-align: inherit;
        font-size: 14px;
    }

    .published {
        color: #08a408;
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            border-radius: 50%;
            background-color: #08a408;
            position: absolute;
            top: 14px;
        }

        &>span {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .pending {
        color: #e48e14;
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            border-radius: 50%;
            background-color: #e48e14;
            position: absolute;
            top: 14px;
        }

        &>span {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .blocked {
        color: #da1010;
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            border-radius: 50%;
            background-color: #da1010;
            position: absolute;
            top: 14px;
        }

        &>span {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    td {
        vertical-align: middle;
        padding: 10px 15px;
        text-align: inherit;
        font-size: 14px;
        word-break: break-word;

        &>a {
            position: relative;
            display: inline-block;

            &:after {
                content: '';
                background-color: color(black);
                width: 100%;
                height: 1px;
                display: block;
                transition: .3s ease-in-out;
            }

            &:hover {
                &:after {
                    content: '';
                    background-color: color(orange);
                    width: 80%;
                    height: 1px;
                    display: block;
                    transition: .3s ease-in-out;
                }
            }
        }

        .engage {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;
            grid-gap: 5px;

            .material-icons-two-tone {
                font-size: 20px;
                padding-right: 5px;
                opacity: 0.6;
            }
        }
    }

    .vertical-align-top {
        td {
            vertical-align: top;
        }
    }
}

.multiselect-dropdown {
    .dropdown-btn {
        .selected-item-container {
            margin: 0 0 5px 0;
        }
    }
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    line-height: 20px !important;
    height: 100% !important;
    padding: 4px 8px !important;
}

ngx-tabset {
    position: relative;
    float: left;
    width: 100%;

    ul.nav-tabset {
        position: absolute;
        top: -52px;
        right: 24px;
    }
}

.step-app {
    padding: 25px;

    .step-steps {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        max-width: 580px;
        margin: 0 auto;
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            background-color: #bababa;
            height: 1px;
            width: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            z-index: -1;
            left: 50%;
        }

        li {
            pointer-events: none;
            background-color: #bababa;
            padding: 10px 35px;
            margin: 0 35px;
            border-radius: 80px;
            font-size: 20px;
            font-weight: bold;
            color: #868686;

            &.completed {
                cursor: pointer;
                pointer-events: all;
                background-color: #398e3d;
                color: #fff;
            }

            &.active {
                cursor: pointer;
                pointer-events: all;
                background-color: #e5b72e;
                color: #fff;
            }
        }
    }

    .step-content {
        float: left;
        width: 100%;
        margin-top: 30px;
    }
}

.ps {
    overflow: hidden;
}

.ps>.ps__rail-y {
    width: 2px;
    background-color: rgba(53, 88, 140, 0.06);
    z-index: 10;
    position: absolute;
    left: auto !important;
    right: 0;
    opacity: 0;
    margin: 1px;
    transition: opacity 0.2s;
}

@media (prefers-reduced-motion: reduce) {
    .ps>.ps__rail-y {
        transition: none;
    }
}

.ps>.ps__rail-y>.ps__thumb-y {
    position: absolute;
    border-radius: 0;
    width: 2px;
    left: 0;
    background-color: #d8dee6;
}

.ps.ps--active-y:hover>.ps__rail-y,
.ps.ps--active-y:focus>.ps__rail-y {
    opacity: 1;
}

.scrollbar-sm>.ps__rail-y {
    width: 1px;
}

.scrollbar-sm>.ps__rail-y>.ps__thumb-y {
    width: 1px;
}

.scrollbar-lg>.ps__rail-y {
    width: 6px;
}

.scrollbar-lg>.ps__rail-y>.ps__thumb-y {
    width: 6px;
}

ngx-daterangepicker-material{
    .md-drppicker{
      display: grid;
      grid-template-columns: 140px auto auto;
      top: 125% !important;
      right: 0 !important;
      left: auto !important;
      .buttons{
        grid-column: 1/ -1;
        .buttons_input{
          display: flex;
          align-items: center;
          justify-content: end;
          margin: 12px;
          .btn{
            padding: 2px 20px;
            font-size: 14px;
            border-radius: 5px;
            margin-left: 10px;
          }
          .btn-default{
            width: auto;
            display: flex;
            align-items: center;
            background-color: #3f51b5 !important;
            color: #fff;
            svg{
                display: none;
            }
          }
        }
      }
    }
  }

  ngx-tabset{
    .nav-tab{
        span{
            font-size: 17px;
        }
    }
}

.heading-sub{
    font-size: 18px;
    position: relative;
    border-bottom: 1px solid #aeaeae;
    padding-bottom: 0.6rem;
    font-weight: 600;

    h2{
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        align-items: center;
        gap: 10px;
    }
}

.advanced-card{
    background-color: #fff;
    padding: 2rem;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.07);
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}

.advanced-edit{
    article{
        .no-content{
            color: rgba(0,0,0,0.5);
        }
    }

    .inner-box{
        .copy-btn{
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 13px;
            opacity: 0;
            transition: opacity .2s ease;
        }

        &:hover{
            .copy-btn{
                opacity: 1;
            }
        }
    }
}
