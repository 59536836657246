@import './mixin';
@import './../project/colors';
@import './../project/typo';




//-- GENERTAE BACKGROUND AND TEXT COLOR --//
@each $colorName, $colorValue in $allColors{
    .bg--#{$colorName}{
        background-color: $colorValue !important;
    }
    .c--#{$colorName}{
      color: $colorValue !important;
    }
    
    .hvr\:bg--#{$colorName}{
        &:hover, &:focus{
            background-color: $colorValue !important;
        }
    }
    .hvr\:c--#{$colorName}{
        &:hover, &:focus{
            color: $colorValue !important;
        }
    }
}




//-- DISPLAY --//
$displays: (
    none,
    inline,
    block,
    inline-block,
    flex,
    inline-flex,
    grid,
    inline-grid
);

@each $display in $displays {
    .d-#{$display}{
        display: $display !important;
    }
}



//-- MARGIN AND PADDING --//
$gaps:(
    auto, 0, 1, 2, 3
);

$gap-dir:(
    'l': left,
    'r': right,
    't': top,
    'b': bottom
);


.m-0{
    margin: 0 !important;
}
.p-0{
    padding: 0 !important;
}
.p-1{
    padding: 1rem !important;
}
.p-2{
    padding: 1.5rem !important;
}
.p-3{
    padding: 2rem;
}
.border-none{
    border: none !important;
}
.font-24{
    font-size: 24px;
}
.border-bottom-1{
    border-bottom: 1px solid currentColor;
}

@each $pos, $dir in $gap-dir{
    @each $gap in $gaps{
        @if($gap == 'auto'){
            // 'auto' applies for margin only
            .m#{$pos}-#{$gap}{ 
                margin-#{$dir}: $gap !important;
            }
        }
        @else if($gap == 0){
            .m#{$pos}-#{$gap}{ 
                margin-#{$dir}: $gap !important;
            }
            .p#{$pos}-#{$gap}{ 
                padding-#{$dir}: $gap !important;
            }
        }
        @else{
            // other value for both margin and padding
            .m#{$pos}-#{$gap}{ 
                margin-#{$dir}: #{$gap}rem !important;
            }
            .p#{$pos}-#{$gap}{
                padding-#{$dir}: #{$gap}rem !important;
            }
        }
    }
}



// BORDER
@each $pos, $dir in $gap-dir{
    .b#{$pos}-0{ 
        border-#{$dir}: none !important;
    }
}

.hvr\:b-0:hover{
    border-color: transparent !important;
}


// FLOAT AND CLEAR
.fl-left{
    float: left;
}
.fl-right{
    float: right;
}
.cfix{
    @include clearfix();
} 



//-- TEXT ALIGN --//
$text-align:(
    left,
    right,
    center,
    justify
);


@each $ta in $text-align{
    .text-#{$ta}{ 
        text-align: $ta;
    }
}




.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}


.w-100{
    width: 100%;
    flex: 0 0 100%;
}

.w-100p{
    width: 100%;
}


.w-50{
    width: 50%;
}

.w-1\/2{
    width: 48% !important;
    flex: 0 0 48% !important;
}

.fullH{
    @include fullH();
}

.rounded{
    border-radius: 50%;
}

.less-rounded{
    border-radius: 1rem;
}

.xs-rounded{
    border-radius: 10px;
}

.colspan-2{
    grid-column: 1/-1;
}

.posiR{
    position: relative;
}

.posiA{
    position: absolute;
}

.posi-left{
    left: 0;
}
.posi-top{
    top: 0;
}
.posi-bottom{
    bottom: 0;
}
.posi-right{
    right: 0;
}


[class="flow-root"],
[class*="flow-rootx"],
[class*="flow-rootX"]{
    [style*="display: none"] + *{
        margin-top: 0 !important;
    }
}

.flow-root > *:not(:first-child) {
    margin-top: 1.5rem;
    @media (max-width: 575px) {
      margin-top: 1rem;
    }
  }

.flow-rootx > *:not(:first-child) {
  margin-top: 1rem;
}

.flow-rootx2 > *:not(:first-child) {
  margin-top: 0.8rem;
}

.flow-rootx3 > *:not(:first-child) {
  margin-top: 0.4rem;
}

.flow-rootx4 > *:not(:first-child) {
    margin-top: 0.3rem;
  }
  

.flow-rootX2 > *:not(:first-child) {
  margin-top: 2rem;
}

.flow-rootX3 > *:not(:first-child) {
  margin-top: 3rem;
  @media (max-width: 575px) {
    margin-top: 2rem;
  }
}

.flow-rootX4 > *:not(:first-child) {
    margin-top: 4rem;
    @media (max-width: 575px) {
      margin-top: 2rem;
    }
}

.flow-rootX5 > *:not(:first-child) {
    margin-top: 5rem;
    @media (max-width: 575px) {
      margin-top: 2.5rem;
    }
}

.flow-rootX6 > *:not(:first-child) {
    margin-top: 8rem;
    @media (max-width: 575px) {
      margin-top: 7rem;
    }
}


.flow-rootRx2 > *:not(:last-child) {
  margin-right: 0.8rem;
}

.flow-rootRx > *:not(:last-child) {
    margin-right: 1rem;
}
  

.flow-rootR > *:not(:last-child) {
  margin-right: 1.5rem;
}

.flow-rootRX2 > *:not(:last-child) {
  margin-right: 2rem;
}

.flow-rootRX3 > *:not(:last-child) {
  margin-right: 3rem;
}

.flow-rootP > *:not(:last-child) {
  padding-bottom: 1.5rem;
}

.flow-rootPX2 > *:not(:last-child) {
  padding-bottom: 2rem;
}

.flow-rootPX3 > *:not(:last-child) {
  padding-bottom: 3rem;
}


.img-hover-zoom{overflow:hidden}
.img-hover-zoom img{-webkit-transition:6s linear;transition:6s linear}
.img-hover-zoom:hover img{-webkit-transform:scale(1.8);transform:scale(1.8)}

.of-hidden{
    overflow: hidden;
}