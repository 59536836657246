@import './../abstracts/mixin';
@import './../abstracts/flex-mixin';
@import './colors';
@import './typo';
@import './common';


.sds-modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 999;
    background-color: rgba(#000, 0.9);
    opacity: 0;
    pointer-events: none;
    transition-delay: 0.3s;
    .close-modal{
      position: absolute;
      right: 15px;
      top: 15px;
    }
    // MODAL CENTER
    &.sds-modal-center{
        overflow: hidden;
        .sds-modal-box{
            width: 92%;
            position: absolute;
            max-width: 700px;
            transform:translate(-50%, -50%);
            top: 50%;
            left: 50%;
            margin-bottom: 40px;
            opacity: 0;
            will-change: opacity,top;
            transition: 0.2s linear;
            &.large{
              max-width: 1200px;
            }
            &.m-large{
              max-width: 950px;
              top: 0;
              transform: translate(-50%, -0%);
            }
            &.medium{
              max-width: 800px;
            }
            &.small{
              max-width: 580px;
            }
            &.small-2{
                max-width: 400px;
            }
        }
        &.is-active{
            opacity: 1;
            pointer-events: auto;
            transition: 0.3s;
            .sds-modal-box{
                opacity: 1;
                transition-delay: 0.3s;
            }
        }
    }
    .sds-modal-container{
        max-height: calc(100vh - 150px);
        overflow: auto;
        padding-right: 20px;
        padding-top: 15px;
    }

    // MODAL SIDE
    &.sds-modal-side{
        overflow: hidden;
        .sds-modal-box{
            width: 900px;
            position: absolute;
            height: 100vh;
            background-color: color(white);
            overflow: auto;
            float: right;
            transform: translateX(100%);
            opacity: 0;
            will-change: opacity, transform;
            transition: 0.2s linear;
        }
        &.is-active{
            opacity: 1;
            pointer-events: auto;
            transition: 0.3s;
            .sds-modal-box{
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}


.sds-modal-header{
    width: 100%;
    padding: 15px 25px;
    background-color: color(lgrn);
    border-radius: 1em 1em 0 0;
    position: relative;
}

.sds-modal-content {
    padding: 30px;
    background-color: color(white);
    overflow: hidden;
    border-radius: 1em;
    .title{
      font-size: 22px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      @include fw(m);
    }
    .sub-content{
      font-size: 14px;
      font-weight: 400;
    }
    .sds-modal-close{
      width:30px;
      height:30px;
      top: 30px;
      right: 30px;
      opacity: 1;
      z-index: 999;
      position: absolute;
      font-weight: 500;
      &:before, &:after{
        background-color: color(white);
        right: 3px;
        margin-top: -2px;
        content: '';
        display: inline-block;
        width: 24px;
        height: 2px;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      &:after {
        transform: rotate(-45deg);
      }
      &:before {
        transform: rotate(45deg);
      }
    }
  }

  .formarea {
    padding: 45px;
    .form-style {
      margin: 60px 0 0 0;
      .email,.password {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0.5;
          width: 30px;
          height: 30px;
        }
        .form-field {
          padding-right: 45px;
        }
        &.has-value{
          &:before {
            opacity: 1;
          }
        }
      }
      // .email{
      //   &:before {
      //     background: url(./../img/icons/email.svg) no-repeat center center;
      //     background-size: 30px;
      //   }
      // }
      // .password{
      //   &:before {
      //     background: url(./../img/icons/password.svg) no-repeat center center;
      //     background-size: 30px;
      //   }
      // }
    }
    .forgetpassword {
      padding: 20px 0 35px;
      text-align: center;
      a {
        font-size: toRem(16);
        color: #7d7d7d;
        transition: 0.4s;
        &:hover {
          color: color(lgrn);
        }
      }
    }
    .btn {
      text-transform: uppercase;
    }
    .loginwith{
      margin: 40px 0 0 0;
      .or {
        position: relative;
        color: color(black);
        &::before{
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          top: 50%;
          background-color: color(border);
          height: 1px;
          z-index: 1;
        }
        span {
          padding: 0 20px;
          background-color: color(white);
          position: relative;
          z-index: 9;

        }
      }
      .alternate {
        .blk {
          border-radius: 50px;
          padding: 6px 15px;
          border: 1px solid color(black);
          transition: 0.4s;
          overflow: hidden;
          a {
            color: color(black);
            font-size: toRem(16);
            text-align: center;
            line-height: 32px;
            display: block;
            span {
              float: left;
            }
          }
          &:hover {
            border-color: color(lgrn);
          }
        }
      }
    }
  }
  #editWidget{
    .widget__controler{
      h3{
        font-size: 18px;
        @include fw(m);
      }
    }
    .grid__column-close{
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #f31c1c;
      color: #ffffff;
      text-align: center;
      text-transform: lowercase;
      margin-top: 40px;
      a{
        padding: 6px;
      }
    }
    .grid__column-add{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ff6d6d;
      color: #ffffff;
      text-align: center;
      text-transform: lowercase;
      a{
        padding: 10px;
        font-size: 26px;
      }
    }
  }
