@import './../abstracts/mixin';
@import './../project/colors';
@import './../project/typo';


@media(min-width: 1400px){
    :root{
        --btn-fs: 1.05rem;
        --btn-size: 10px 20px;
        --btn-sizeEX: 1.25em 2.5em;
    }
}

.btns{
    & > *:not(:last-child){
        margin-right: 0.5em;
    }
}

.btn, .link, .underlined{
    display: inline-flex;
    white-space: nowrap;
    font-size: var(--btn-fs, 0.95rem);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.5s ease;
}

.btn{
    font-size: 1rem;
    //padding: 0.8em 1.6em;
    padding: 0.6em 1.2em;
    border:1px solid transparent;
    text-decoration: none;
    @each $colorName, $colorValue in $allColors{
        &.btn-#{$colorName}{
          //  @extend .bg-#{$colorName};
            @extend .c-#{$colorName};
            // border-color: $colorValue;
            &.outline, &.outlineB{
                border-color: $colorValue;
                &:hover{
                    @extend .bg-#{$colorName};
                    border-color: $colorValue;
                }
                &.more-rounded{
                    border-radius: 2em;
                }
            }
            &.outline{
                &:hover{
                    color: color(white);
                }
            }
            &.outlineB{
                &:hover{
                    color: color(black);
                }
            }
        }
    }
    &.outline, &.outlineB{
        background-color: transparent;
    }
    &.solid{
        color: color(white);
    }
    &.solidB{
        color: color(black);
    }
    &.sm-rounded{
        border-radius: 0.2em;
    }
    &.rounded{
        border-radius: 0.4em;
    }
    &.more-rounded{
        border-radius: 2em;
    }
    &.mid{
        font-size: 1.1rem;
    }
    &.small{
        font-size: 14px;
        padding: 0.5em 1.1em;
        @media(max-width: 760px){
            font-size: 13px;
            padding: 0.3em 1.1em;
        }
    }
    &.tiny{
        font-size: 14px;
        padding: 0.3em 0.8em;
        @media(max-width: 760px){
            font-size: 12px;
        }
    }
    &.align-center{
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    &.border2X{
        border-width: 2px;
    }

    &:hover,
    &:focus{
        filter: saturate(50%);
        -webkit-filter: saturate(50%);
    }
}

.underlined{
    padding-bottom: 0.5em;
    position: relative;
    &::before,
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        background-color: currentColor;
        overflow: hidden;
        transition: inherit;
    }
    &::before{
        width: 100%;
        left: 0;
    }
    &::after{
        width: 0;
        right: 0;
    }
    &:hover{
        &::before{
            width: 0;
            left: auto;
            right: 0;
        }
        &::after{
            width: 100%;
            left: 0;
            right: auto;
            transition-delay: 0.35s;
        }
    }
}

.btn, .link{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    & > *:not(:last-child){
        margin-right: 1em;
    }
    svg{
        transition: inherit;
    }
    &:hover{
        svg{
            transform: translateX(0.8em);
        }
    }
}

.btn-arw{
    display: inline-grid;
    grid-auto-flow: column;
    gap: 1.2em;
    font-size: toRem(18);
    place-items: center;
    transition: all 0.5s ease;
    img{
        width: 1.5em;
        transform: translateX(0);
        transition: inherit;
    }
    &:hover{
        color: color(lgrn);
        img{
            transform: translateX(1em);
        }
    }
}

.schedule-btns{
    .btn{
        border-radius: 5px;
        width: 100%;
        font-size: 18px !important;
        padding: 16px;
        &:not(:last-child){
            margin-bottom: 10px;
        }
    }
}

// .btns--social-logins{
//     margin-bottom: 10px;
// }

// .btn--social-signin {
//     display: flex;
//     align-items: center;
//     padding: 0.5em 1em;
//     min-height: 45px;
//     border-radius: 12px;
//     font-family: inherit;
//     font-style: normal;
//     @include fw(sb);
//     font-size: 13px;
//     letter-spacing: 0.03em;
//     color: color(white);
//     margin-bottom: 12px;
//     position: relative;
//     &--google {
//         border: 1px solid #777777;
//         background-color: color(white);
//         color: #333232;
//     }
//     &--apple {
//         background: color(black);
//         border: 1px solid color(black);
//     }
//     &--facebook {
//         background: color(facebook);
//         border: 1px solid color(facebook);
//     }
//     &--mobile{
//         border: 1px solid #777777;
//         background-color: color(white);
//         color: #333232;
//         &:hover, &:focus{
//             border-color: 333232;
//             background-color: #333232;
//             color: color(white);
//         }
//     }

//     span:nth-child(2) {
//         margin: 0 auto;
//     }
// }
