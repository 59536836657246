@import './../abstracts/mixin';
@import './../abstracts/flex-mixin';
@import './colors';
@import './typo';
@import './common';
@import './../components/star-ratings';

:root {
    --low-radius: 10px;
    --dp-shadow: 1px 0 1px rgba(216,222,230,.25),2px 0 2px rgba(216,222,230,.2),4px 0 4px rgba(216,222,230,.15),8px 0 8px rgba(216,222,230,.1),16px 0 16px rgba(216,222,230,.05);
    --card-shadow: 0px 0px 19px 0px rgba(0,0,0,0.05);
    --full-height: 100vh;
    --oval-radius: 50%;
}
body{
    background-color: color(theme-bg);
}
a {
    cursor: pointer;
}
.sidebar{
    width: 320px;
    background-color: color(white);
    box-shadow: var(--dp-shadow);
    transition: all .3s;
    position: fixed;
    top: 0;
    height: var(--full-height);
    display: flex;
    flex-direction: column;
    @media (max-width: 1500px){
        width: 280px;
    }
    .sidebar-header{
        padding: 35px 20px;
        align-items: center;
        // height: 70px;
        position: relative;
        background-color: color(lightyellow);
        float: left;
        width: 100%;
        .top-header{
            display: flex;
            flex-shrink: 0;
        }
        .sidebar-logo {
            padding: 0 8px;
        }
        .sidebar-logo-text {
            margin-left: 5px;
            opacity: 1;
            transition: all .3s ease;
        }
        .ham__menu{
            position: absolute;
            right: 25px;
            top: 38px;
            .material-icons-outlined{
                color: #4f4b4f;
            }
            .content-menu{
                position: relative;
                &:after{
                    content: '\e9bd';
                    font-family: 'Material Icons Round';
                    font-weight: normal;
                    font-style: normal;
                    font-size: 24px;
                    color: #404040;
                }
            }
        }
        .profile{
            position: relative;
            float: left;
            width: 100%;
            display: flex;
            align-items: center;
            margin: 30px 0 10px;
            opacity: 1;
            & > figure{
                border-radius: var(--low-radius);
                overflow: hidden;
                margin-right: 15px;
                width: 54px;
                flex: 0 0 54px;
            }
            & > article{
                h2{
                    font-size: 18px;
                    color: color(hvy-darkgray);
                    @include fw(b);
                }
                p{
                    color: color(midlght-gray);
                    font-size: 14px;
                }
            }
        }
    }
    .sidebar-body {
        height: 100%;
        position: relative;
        .nav-sidebar {
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;

            .nav-item {
                list-style: none;
                .nav-sub {
                    // display: none;
                    flex-direction: column;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    background-color: color(lightgrey);
                    border-radius: var(--low-radius);
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 1s ease-out;
                    .sub-link {
                        height: 5px;
                        padding: 18px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        transition: .3s ease-in-out;
                        color: color(drk-gray);
                        &:first-child{
                            padding-top: 30px;
                        }
                        &:last-child{
                            padding-bottom: 30px;
                        }
                        &:before{
                            content: '_';
                            display: inline-block;
                            font-size: 30px;
                            margin-right: 0;
                            opacity: 0;
                            transition: .3s ease-in-out;
                        }
                        &:hover{
                            margin-left: 10px;
                            transition: .3s ease-in-out;
                            &:before{
                                content: '_';
                                display: inline-block;
                                font-size: 30px;
                                margin-right: 5px;
                                opacity: 1;
                                transition: .3s ease-in-out;
                            }
                        }
                    }
                }
                &.show{
                    .nav-sub {
                        height: auto;
                        max-height: 500px;
                        transition: max-height 1s ease-in !important;
                    }
                }
            }
            .nav-link {
                height: 45px;
                padding: 0 10px;
                display: flex;
                align-items: center;
                border-radius: 5px;
                white-space: nowrap;
                color: color(drk-gray);
                .material-icons-two-tone{
                    opacity: 0.6;
                    margin-right: 10px;
                    font-size: 22px;
                }
                &.with-sub{
                    position: relative;
                    &:after{
                        content: '\e5cf';
                        font-family: 'Material Icons Outlined';
                        font-weight: normal;
                        font-style: normal;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}
.content{
    margin-left: 320px;
    position: relative;
    min-height: var(--full-height);
    transition: all .3s ease;
    @media (max-width: 1500px){
        margin-left: 280px;
    }
    .content-body {
        padding: 35px;
        float: left;
        width: 100%;
        .content-main{
            float: left;
            width: 100%;
            .tag{
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}

.cdk-overlay-container {
  position: fixed;
  z-index: 99999 !important;
}
.angular-editor .angular-editor-wrapper .angular-editor-textarea {
  min-height: 5rem;
  padding: 0.5rem 1.4rem 1rem !important;
  word-break: break-word;
}

.nav-tabset:not(.disable-style){
  top: auto!important;
  right: auto!important;
  position: relative!important;
  justify-content: center!important;
}

.nav-tabset:not(.disable-style) .nav-tab{
  font-weight: bold;
}

.nav-tabset:not(.disable-style) .nav-tab.active{
  border-color: #fbc011 !important;
}

.poll__post-numbers{
    counter-reset: my-sec-counter;
    .card-1{
        position: relative;
        border-radius: var(--low-radius);
        display: grid;
        grid-auto-rows: auto 1fr;
        &:before{
            counter-increment: my-sec-counter;
            content: counter(my-sec-counter);
            position: absolute;
            right: 0;
            top: 0;
            background: #d9590c;
            padding: 8px 18px;
            font-size: 18px;
            color: white;
            font-weight: bold;
            border-bottom-left-radius: 20px;
        }
    }
}

.distribution__lock{
    .card-1{
        display: grid;
        grid-auto-rows: auto 1fr;
    }
}

.poll-answer{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.content__dashboard{
    .grid-5{
        gap: 4rem 1.4rem;
        padding: 3rem 0 0;
        @media (max-width: 1700px){
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
        @media (max-width: 1400px){
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
    .popover__wrapper {
        position: relative;
        display: inline-block;
        .popover__content {
            opacity: 0;
            visibility: hidden;
            width: 450px;
            position: absolute;
            z-index: 9;
            left: 0;
            top: -20px;
            margin-left: 60px;
            transform: translate(0, 10px);
            transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
            box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.10);
            border-radius: 12px;
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 22px;
                left: -14px;
                width: 0px;
                height: 0px;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-right: 14px solid #bacce6;
            }
            .popover__message__header{
                background: #bacce6;
                padding: 1rem 1.6rem;
                font-size: 20px;
                @include fw(m);
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
            .popover__message__body{
                padding: 1.6rem;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                background-color: color(white);
                border: 1px solid #bacce6;
                p{
                    font-size: 15px;
                }
                ul{
                    list-style: inside decimal;
                    li{
                        font-size: 14px;
                    }
                }
            }
        }
        &:hover{
            .popover__content {
                z-index: 10;
                opacity: 1;
                visibility: visible;
                transform: translate(0, 0);
                transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
            }
        }
    }
    .content__info{
        width: 25px;
        height: 25px;
        background-color: #bacce6;
        text-align: center;
        color: color(black);
        border-radius: 50%;
        font-size: 14px;
        @include fw(b);
        margin-left: 0.6rem;
        line-height: 1.8;
    }
    &.two__columns{
        display: grid;
        grid-template-columns: auto 420px;
        gap: 3rem;
        transition: all 0.3s ease;
        @media (max-width: 1830px){
            grid-template-columns: auto 400px;
        }
        @media (max-width: 1530px){
            grid-template-columns: auto 360px;
        }
        .left__content{
            .__profile{
                background-color: #e7ecf8;
                padding: 1.4rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: var(--low-radius);
                figure{
                    display: flex;
                    align-items: center;
                    img{
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    figcaption{
                        margin-left: 16px;
                        h2{
                            font-size: 20px;
                            @include fw(m);
                        }
                        p{
                            font-size: 12px;
                        }
                    }
                }
            }
            .webinar__edit{
                .webinar__heading{
                    font-size: 24px
                }
                .form-upload{
                    .btn{
                        float: left;
                    }
                }
                .webinar_attach__speaker{
                    border: 1px solid color(verylightbg);
                    .web__heading{
                        padding: 1.5rem;
                        background-color: color(verylightbg);
                        color: color(black);
                    }
                    .web__body{
                        padding: 3rem;
                        @media (max-width: 1540px){
                            padding: 2rem;
                        }
                    }
                }
            }
            .article__edit{
                .letter__case{
                    p{
                        @include fw(m);
                        margin-right: 16px;
                    }
                    .buttons{
                        display: grid;
                        grid-auto-flow: column;
                        gap: 10px;
                        .sm-btn{
                            background-color: #e7ecf8;
                            padding: 4px 10px;
                            font-size: 16px;
                            @include fw(b);
                            cursor: pointer;
                        }
                    }
                }

                .suggested__comments,
                .frequently__question,
                .add__url{
                    background-color: #e7ecf8;
                    padding: 1.4rem;
                    h4{
                        font-size: 18px;
                        @include fw(m);
                    }
                    .tag{
                        span{
                            background-color: #919aad !important;
                            color: white !important;
                        }
                    }
                    .faq__block{
                        background-color: color(white);
                        padding: 1rem;
                        h5{
                            border-bottom: 1px solid #e2e2e2;
                            color: #4a4a4a;
                            @include fw(b);
                            padding-bottom: 10px;
                        }
                        p{
                            padding-top: 10px;
                            font-size: 14px;
                            color: #4a4a4a;
                            display: grid;
                            grid-auto-flow: column;
                            align-items: center;
                            justify-content: space-between;

                            .material-icons-outlined{
                                font-size: 20px;
                            }
                        }
                    }

                    .url__grid{
                        display: grid;
                        grid-template-columns: 78% auto;
                        align-items: center;
                        gap: 1rem;
                        place-content: space-between;

                        &:not(:last-child){
                            margin-bottom: 1.5rem;
                        }

                        .grid-action{
                            display: grid;
                            grid-auto-flow: column;
                            align-items: center;
                            gap: 1rem;
                        }
                    }
                }
                &.collapse__block{
                    position: relative;
                    .collapse__arrow{
                        font-weight: normal;
                        font-style: normal;
                        background-color: #fcc012;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        text-align: center;
                        display: block;
                        position: absolute;
                        left: 50%;
                        z-index: 2;
                        bottom: -20px;
                        font-size: 24px;
                        line-height: 2;
                        cursor: pointer;
                    }
                    .collapse{
                        position: relative;
                        height: 100px;
                        overflow: hidden;
                        &:before{
                            content: "";
                            width: 100%;
                            height: 100%;
                            background: white;
                            background: linear-gradient(0deg, white 21%, rgba(255, 255, 255, 0) 100%);
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            z-index: 1;
                        }

                    }
                    &.minimized__block{
                        .collapse{
                            height: 100%;
                            &:before{
                                display: none;
                            }
                        }
                        .collapse__arrow{
                            transform: rotate(180deg);
                        }
                        .card-content{
                            padding-bottom: 3rem !important;
                        }
                    }
                }

            }
        }
        .right__content{
            position: relative;
            transition: all 0.3s ease;
            .collapse__right_menu{
                background-color: #797979;
                padding: 2px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                position: absolute;
                left: -28px;
                top: 20px;
                transition: all 0.3s ease;
                .material-icons-outlined{
                    color: white;
                    margin-top: 6px;
                    transition: all 0.3s ease;
                }
            }
            .cancel__edit{
                background-color: #fbc011;
                padding: 1.4rem;
                border-top-left-radius: var(--low-radius);
                border-top-right-radius: var(--low-radius);
                a{
                    font-size: 20px;
                    @include fw(b);
                }
            }
            .post__status{
                background-color: #6d5613;
                display: flex;
                align-items: center;
                justify-content: space-between;
                p{
                    color: color(white);
                    font-size: 14px;
                    @include fw(m);
                }
            }
            .content__category{
                padding: 1.4rem;
            }
        }
        &.minimized__right{
            grid-template-columns: auto 0px;
            gap: 1.4rem;
            transition: all 0.3s ease;
            .right__content{
                position: absolute;
                right: 20px;
                width: 0px;
                transition: all 0.3s ease;
                .collapse__right_menu{
                    transition: all 0.3s ease;
                    .material-icons-outlined{
                        transform: rotate(180deg);
                        transition: all 0.3s ease;
                    }
                }
                .post__status,
                .content__category{
                    display: none;
                }
            }
        }
    }
}

.upload-pdf-files{
    max-height: 420px;
    overflow-y: auto;
    .files-block{
        padding: 1rem;

        &:not(:last-child){
            border-bottom: 1px solid #ccc;
        }

        .files-box{
            display: grid;
            gap: 1rem;

            .files__link{
                display: grid;
                grid-auto-flow: column;
                gap: 0.6rem;
                align-items: start;

                .material-icons-outlined{
                    font-size: 28px;
                    color: #075dcc;
                }
            }

            .files__action{
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                padding: 0.6rem 1rem;
                border: 1px solid #ccc;
                border-radius: 0.3rem;
                place-content: space-between;

                label{
                    font-size: 16px;
                }
            }
        }
    }
}
.upload-featured-container{
    max-height: 420px;
    overflow-y: auto;

    .upload-featured-image{
        position: relative;
        border: 1px solid #b9b9b9;
        padding: 10px;
        float: left;
        width: 100%;
        margin-bottom: 1rem;

        .upload-image-block{
            display: grid;
            grid-gap: 1rem;
            gap: 1rem;

            figure{
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;

                img{
                    width: 100%;
                    max-height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }

            label{
                font-size: 16px;
            }
        }
    }
}

.dashboard__card{
    border-radius: 10px;
    padding: 1rem;
    background-color: #717171;
    position: relative;
    overflow: hidden;
    transition: .5s ease-in;
    &::after{
        content: "";
        width: 260px;
        height: 250%;
        background-color: rgba(255, 255, 255, 0.1);
        position: absolute;
        left: -10%;
        top: 20%;
        transform: translateY(-50%) rotate(30deg);
        transition: .5s ease-in;
    }
    .dashboard__profile{
        display: grid;
        grid-template-columns: 45px auto;
        gap: 1rem;
        align-items: center;
        figure{
            width: 45px;
            height: 45px;
            border-radius: 50%;
            overflow: hidden;
            img{
                object-fit: cover;
            }
        }
        article{
            h1{
                font-size: 20px;
                @include fw(m);
                color: color(white);
                transition: .5s ease-in;
            }
            h2{
                font-size: 12px;
                color: color(white);
                transition: .5s ease-in;
            }
        }
    }
    .draft-list-content {
        p {
            font-size: 16px;
            @include fw(m);
            max-width: 340px;
            line-height: 1.3;
            color: color(white);
            transition: .5s ease-in;
            @media (max-width: 1400px){
                font-size: 14px;
            }
        }
    }
    .dashboard__profile-content{
        p{
            font-size: 20px;
            @include fw(m);
            max-width: 340px;
            line-height: 1.3;
            color: color(white);
            transition: .5s ease-in;
            @media (max-width: 1400px){
                font-size: 16px;
            }
        }
    }
    .d__actions{
        list-style: none;
        position: absolute;
        z-index: 9;
        right: -90px;
        top: 5px;
        display: grid;
        grid-template-columns: 30px 30px;
        gap: 1rem;
        opacity: 0;
        transition: .5s ease-in;
        @media (max-width: 1400px){
            grid-template-columns: 18px 18px;
        }
        .material-icons-two-tone{
            @media (max-width: 1400px){
                font-size: 18px;
            }
        }
    }
    &:hover{
        background-color: color(yellow);
        transition: .5s ease-in;
        &::after{
            content: "";
            width: 260px;
            height: 250%;
            background-color: rgba(255, 255, 255, 0.2);
            position: absolute;
            left: -10%;
            top: 20%;
            transform: translateY(-50%) rotate(30deg);
            transition: .5s ease-in;
        }
        .dashboard__profile{
            article{
                h1{
                    color: color(black);
                    transition: .5s ease-in;
                }
                h2{
                    transition: .5s ease-in;
                    color: color(black);
                }
            }
        }
        .dashboard__profile-content{
            p{
                color: color(black);
                transition: .5s ease-in;
            }
        }
        .d__actions{
            right: 20px;
            opacity: 1;
            transition: .5s ease-in-out;
        }
    }
}
#alldrafts{
    .grid-3{
        gap: 1rem;
    }
    .dashboard__profile-content{
        p{
            font-size: 18px;
        }
    }
    .nomore__content{
        font-size: 26px;
        color: #7c7c7c;
    }
}

.community-profile{
    background: white;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 1rem;
    display: grid;
    height: 91%;
    align-items: center;
    justify-content: center;
    text-align: center;

    .desig{
        font-size: 14px;

        span{
            color: #0034ff;
            font-weight: bold;
        }
    }
}

.community-follower{
    padding: 2rem 0;
    margin-bottom: 1.5rem;
    background: #e6ebf7;
    border-radius: 10px;

    & > *{
        text-align: center;
        display: grid;
        line-height: 1.3;

        &:not(:last-child){
            border-right: 1px solid #c2c8d5;
        }
    }

    a{
        font-size: 28px;
        color: black;
        font-weight: bold;
    }

    span{
        font-weight: 300;
        font-size: 14px;
    }
}

.icd-dropbox{
    background: #e6ebf7;
    padding: 1rem 1.5rem;
    border-radius: 4px;

    ul{
        list-style: decimal inside;
        display: grid;
        gap: 0.5rem;

        li{
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;

            &:not(:last-child){
                border-bottom: 1px solid #b8c0d5;
                padding-bottom: 0.7rem;
            }
        }
    }
}

.icd-support-box{
    padding: 1rem 1.5rem;
    border: 2px solid #ccd1db;
    border-radius: 4px;
    max-height: 500px;
    overflow-y: auto;

    .sep-box{

        .click-code{
            font-size: 20px;
            margin-bottom: 10px;
            float: left;
            width: 100%;
        }
    }
}

.icd-10-code{
    padding: 2rem;
    background: #f0f0f0;
    border-radius: 4px;

    .icd-10-btn-container{
        display: grid;
        grid-auto-flow: column;
        justify-content: end;
        align-items: center;
        gap: 0.4rem;
        position: absolute;
        right: 0;
        top: -20px;

        .btn{
            padding: 8px 18px !important;

            .material-icons-outlined{
                font-size: 16px;
            }
        }
    }
}

.prd-btn-container{
    float: left;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    gap: 0.4rem;
    align-items: center;
}

owl-date-time-container{
    width: 24rem !important;

    owl-date-time-calendar{
        height: auto !important;
    }
}

.faq__edit-block{
    height: 240px;
    overflow-y: scroll;
    background: #fff;
    padding: 1em;
    border: 1px solid #ccc;

    ul{
        float: left;
        width: 100%;
        list-style: none;
        display: grid;
        gap: 1rem;

        li{
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            align-items: center;
        }
    }
}
